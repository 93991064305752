export default theme => ({
  root: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.grey[100],
    boxShadow: '-14px -19px 20px 20px rgba(174, 174, 174, 0.1)'
  },
  dropArrow: {
    cursor: 'pointer'
  },
  zoneArrow: {
    width: 24,
    height: 24
  },
  colorPane: {
    paddingTop: '0.5rem',
    backgroundColor: 'white'
  },
  sidebar: {
    padding: '0'
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    opacity: '0.5'
  }
})

export const MenuItemStyle = {
  selected: {
    backgroundColor: 'white !important'
  },
  root: {
    padding: '5px 16px'
  }
}
