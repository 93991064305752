import React, { Component } from 'react'
// import Button from '@material-ui/core/Button'
import IconContentCopy from '@material-ui/icons/ContentCopy'
// import IconEmail from '@material-ui/icons/Email'
// import IconFileDownload from '@material-ui/icons/FileDownload'
import Popover from '@material-ui/core/Popover'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class SaveOrderTitlebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      copied: false
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleCopied = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.mounted && this.setState({ copied: false })
      }, 2000)
    })
  }

  handleClose = () => {
    this.setState({ copied: false })
  }

  handleRef = ref => {
    this.anchorEl = ref
  }

  render() {
    const { classes, instance } = this.props
    const { copied } = this.state
    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.flex}>
            Save Order
          </Typography>
          <IconContentCopy className={classes.iconCopy} />
          <div ref={this.handleRef}>
            <Typography>Copy Reference #</Typography>
          </div>
          <CopyToClipboard onCopy={this.handleCopied} text={instance.id}>
            <TextField className={classes.textField} value={instance.id} margin="dense" />
          </CopyToClipboard>
          {/*
          <Button variant="raised" size="small" className={classes.button}>
            <IconFileDownload className={classes.buttonIcon} /> Download PDF
          </Button>
          <Button variant="raised" size="small" className={classes.button}>
            <IconEmail className={classes.buttonIcon} /> Email
          </Button>
          */}
          <Popover
            open={copied}
            anchorEl={this.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Typography className={classes.popoverText}>Copied to clipboard!</Typography>
          </Popover>
        </Toolbar>
      </div>
    )
  }
}

export default compose(withStyles(styles))(SaveOrderTitlebar)
