import { put, takeLatest } from 'redux-saga/effects'

import { apiCall } from '../api'
import { GET_LOCATIONS, SEARCH_STORES } from './types'
import { showLocations } from './actions'

const getLocations = apiCall({
  type: GET_LOCATIONS,
  method: 'get',
  allowedParamKeys: ['variation', 'line', 'env'],
  path: '/api/v1/locations',
  payloadOnSuccess: data => data.data,
  selectorKey: 'locations',
  success: function*(payload) {
    yield put(showLocations(payload))
  }
})

const searchStores = apiCall({
  type: SEARCH_STORES,
  method: 'get',
  allowedParamKeys: ['q'],
  path: `/api/v1/stores/search`,
  headers: {
    'Content-Type': 'text/html; charset=utf-8',
    Accept: 'text/html; charset=utf-8'
  },
  payloadOnSuccess: data => data.data,
  selectorKey: 'storesList'
})

export default function* rootSaga() {
  yield takeLatest(GET_LOCATIONS, getLocations)
  yield takeLatest(SEARCH_STORES, searchStores)
}
