export default theme => ({
  root: {
    overflow: 'hidden',
    marginBottom: '2rem'
  },
  hide: {
    display: 'none'
  },
  tabBar: {
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  tabBody: {
    zIndex: '0', // -1
    position: 'relative',
    top: '-1px'
  },
  tabOverlap: {
    zIndex: 9
  }
})

export const TabStyle = {
  root: {
    textTransform: 'none',
    backgroundColor: 'lightgray',
    borderBottom: 'white',
    border: 'solid 1px lightgray'
  },
  selected: {
    backgroundColor: 'white',
    color: 'gray !important'
  },
  label: {
    fontSize: '1.1rem',
    color: 'initial',
    fontWeight: 'initial'
  }
}

export const TabsStyle = {
  indicator: {
    opacity: '0'
  }
}
