import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
//import Button from 'components/GradientButton'
//import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

//import InputField from 'components/InputField'
//import Logo from './ccm.png'
import styles from './styles'
import { formSubmit } from 'utils/form'
import { getCustomizerInstance } from 'redux/modules/customizer/actions'

export const instanceIdRequired = value => (!value ? 'Instance ID is required' : undefined)

class ReferenceSection extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    getCustomizerInstance: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  }

  handleEdit = values => {
    const { getCustomizerInstance, history } = this.props
    const { instanceId } = values
    return formSubmit(getCustomizerInstance, {
      id: instanceId,
      success: () => history.push(`/instance/${instanceId}`)
    })
  }

  render() {
    const { classes, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.handleEdit)}>
        <Grid container className={classes.root}>
          <Grid item xs>
            <img
              src={process.env.PUBLIC_URL + '/ccm-logo-v2.png'}
              className={classes.logo}
              alt="CCM"
            />
          </Grid>
        </Grid>
      </form>
    )
  }
}

const actions = {
  getCustomizerInstance
}

export default compose(
  withRouter,
  connect(
    null,
    actions
  ),
  reduxForm({
    form: 'loadInstanceForm'
  }),
  withStyles(styles)
)(ReferenceSection)
