import React, { Component, Fragment } from 'react'
//import MuiButton from '@material-ui/core/Button'
import MuiButton from 'components/GradientButton'
import fp from 'lodash/fp'
import get from 'lodash/get'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { submit, blur } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import ConfirmModal from 'components/ConfirmModal'

import styles, { TabStyles, TabsStyles, NavigationStyles } from './styles'
import {
  customizerInstanceSelector,
  productsSelector,
  userSelector,
  isAjaxCallInProgressSelector
} from 'redux/modules/customizer/selectors'
import { locationsSelector } from 'redux/modules/location/selectors'
import { productSteps } from 'utils/helpers'
import { submitOrder, updateProductImages } from 'redux/modules/customizer/actions'

const getNextStep = step =>
  fp.compose(
    index => fp.nth(Math.min(index + 1, productSteps.length - 1), productSteps),
    fp.findIndex({ key: step })
  )(productSteps)

const getPrevStep = step =>
  fp.compose(
    index => fp.nth(Math.max(index - 1, 0), productSteps),
    fp.findIndex({ key: step })
  )(productSteps)

const Tab = withStyles(TabStyles)(MuiTab)
const Tabs = withStyles(TabsStyles)(MuiTabs)
const Button = withStyles(NavigationStyles)(MuiButton)

class ProductHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool, // used for disabling all navigations and dropdowns
    nextDisabled: PropTypes.bool, // used for disabling next navigation
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    product: PropTypes.object,
    instance: PropTypes.object,
    saveLastZone: PropTypes.func,
    step: PropTypes.string,
    variation: PropTypes.string,
    customLogos: PropTypes.array,
    customAiFilesLogos: PropTypes.array
  }

  constructor(props) {
    super(props)
    if (this.props.variation === 'team') {
      if (this.props.step === 'intro') {
        this.state = {
          openConfirmModal: false,
          titleConfirmModal: 'Attention',
          textConfirmModal:
            'Please press the SAVE button in order to add your logos to your order.  Failure to do so will result in your order being produced with NO logo. Press CANCEL to return to the previous screen to save your logo.',
          textConfirmBtnModal: 'Continue',
          checkNeeded: false
        }
      } else if (this.props.step === 'specs') {
        this.state = {
          openConfirmModal: false,
          titleConfirmModal: 'Please Confirm',
          textConfirmModal:
            'I confirm that zone colors and sizes selected have been reviewed before proceeding.',
          textConfirmBtnModal: 'Finalize',
          checkNeeded: true
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductHeader componentDidUpdate prevProps', prevProps)
      console.log('ProductHeader componentDidUpdate prevState', prevState)
      console.log('ProductHeader componentDidUpdate state', this.state)
    }
  }

  handleNextClick = () => {
    const {
      updateProductImages,
      instance,
      history,
      disabled,
      match: {
        params: { instanceId, productId }
      },
      step,
      variation,
      submit,
      blur
    } = this.props
    const nextStep = getNextStep(step).key

    if (disabled || this.props.isAjaxCallInProgress) {
      return
    }
    if (step === 'intro') {
      let hasUnsavedLogo = false
      let hasUnsavedAiFile = false
      if (variation === 'team') {
        for (var keyL in this.props.customLogos) {
          let clogo = this.props.customLogos[keyL]
          if (clogo.file && !clogo.saved) {
            hasUnsavedLogo = true
          }
        }
        for (var keyAi in this.props.customAiFilesLogos) {
          let cAiFile = this.props.customAiFilesLogos[keyAi]
          if (cAiFile.file && !cAiFile.saved) {
            hasUnsavedAiFile = true
          }
        }
      }
      if (variation === 'team' && (hasUnsavedLogo || hasUnsavedAiFile)) {
        this.setState({ openConfirmModal: true })
      } else {
        const products = get(instance, 'attributes.products.data', [])
        const index = fp.findIndex({ id: parseInt(productId, 10) })(products)
        if (index < products.length - 1) {
          history.push(`/instance/${instance.id}/product/${products[index + 1].id}`)
        } else if (nextStep === 'specs') {
          this.props.saveLastZone()
          updateProductImages({
            instanceId: instanceId,
            success: () => history.push(`/instance/${instanceId}/${getNextStep(step).key}`)
          })
        }
      }
    } else if (nextStep === 'finalize') {
      if (variation === 'team') {
        this.setState({ openConfirmModal: true })
      } else {
        blur('productSpecsForm')
        submit('productSpecsForm')
      }
    } else {
      history.push(`/instance/${instanceId}/${getNextStep(step).key}`)
    }
  }

  handleApprovedConfirmModal = () => {
    const {
      submit,
      blur,
      step,
      variation,
      instance,
      history,
      match: {
        params: { instanceId, productId }
      },
      updateProductImages
    } = this.props
    const nextStep = getNextStep(step).key
    this.setState({ openConfirmModal: false })
    if (variation === 'team') {
      if (step === 'intro') {
        const products = get(instance, 'attributes.products.data', [])
        const index = fp.findIndex({ id: parseInt(productId, 10) })(products)
        if (index < products.length - 1) {
          history.push(`/instance/${instance.id}/product/${products[index + 1].id}`)
        } else if (nextStep === 'specs') {
          this.props.saveLastZone()
          updateProductImages({
            instanceId: instanceId,
            success: () => history.push(`/instance/${instanceId}/${getNextStep(step).key}`)
          })
        }
      } else if (step === 'specs') {
        blur('productSpecsForm')
        submit('productSpecsForm')
      }
    }
  }

  handleCloseConfirmModal = () => {
    this.setState({ openConfirmModal: false })
  }

  handlePrevClick = () => {
    const {
      instance,
      history,
      disabled,
      match: {
        params: { instanceId, productId }
      },
      step
    } = this.props
    const products = get(instance, 'attributes.products.data', [])

    if (disabled) {
      return
    }
    if (step === 'intro') {
      const index = fp.findIndex({ id: parseInt(productId, 10) })(products)
      index > 0 && history.push(`/instance/${instance.id}/product/${products[index - 1].id}`)
    } else if (step === 'specs') {
      history.push(`/instance/${instance.id}/product/${products[products.length - 1].id}`)
    } else {
      history.push(`/instance/${instanceId}/${getPrevStep(step).key}`)
    }
  }

  handleProductChange = (event, value) => {
    const {
      history,
      instance,
      updateProductImages,
      disabled,
      saveLastZone,
      variation,
      match: {
        params: { instanceId }
      }
    } = this.props

    if (disabled) {
      return
    }
    if (value === 'specs') {
      let hasUnsavedLogo = false
      let hasUnsavedAiFile = false
      if (variation === 'team') {
        for (var keyL in this.props.customLogos) {
          let clogo = this.props.customLogos[keyL]
          if (clogo.file && !clogo.saved) {
            hasUnsavedLogo = true
          }
        }
        for (var keyAi in this.props.customAiFilesLogos) {
          let cAiFile = this.props.customAiFilesLogos[keyAi]
          if (cAiFile.file && !cAiFile.saved) {
            hasUnsavedAiFile = true
          }
        }
      }

      if (variation === 'team' && (hasUnsavedLogo || hasUnsavedAiFile)) {
        this.setState({ openConfirmModal: true })
      } else if (saveLastZone) {
        saveLastZone()
        updateProductImages({
          instanceId: instanceId,
          success: () => history.push(`/instance/${instanceId}/specs`)
        })
      }
    } else {
      history.push(`/instance/${instance.id}/product/${value}`)
    }
  }

  render() {
    const {
      classes,
      instance,
      nextDisabled,
      step,
      user,
      variation,
      isAjaxCallInProgress,
      match: {
        params: { productId }
      }
    } = this.props
    const location = get(instance, 'attributes.customizer.data.location.name')
    const line = get(instance, 'attributes.customizer.data.line.name')
    const ageGroup = get(instance, 'attributes.customizer.data.age_group.name')
    const products = get(instance, 'attributes.products.data', [])
    const finalize = getNextStep(step).key === 'finalize' && user && user.id && window.parent

    let breadcrumbStr = ''
    if (variation === 'team') {
      breadcrumbStr = location + ' / ' + line
    } else {
      breadcrumbStr = location + ' / ' + ageGroup + ' / ' + line
    }

    return (
      <div className={classes.root}>
        <Typography className={cx(classes.root, classes.title)} variant="title">
          {breadcrumbStr}
        </Typography>
        <Tabs
          value={step === 'specs' ? step : parseInt(productId, 10)}
          onChange={this.handleProductChange}
        >
          {products.map(product => (
            <Tab
              key={product.id}
              value={parseInt(product.id, 10)}
              label={product.name}
              disabled={this.props.isAjaxCallInProgress}
            />
          ))}
          <Tab label="Specs" value="specs" disabled={this.props.isAjaxCallInProgress} />
        </Tabs>

        <Button
          color="primary"
          variant="contained"
          onClick={this.handlePrevClick}
          disabled={products[0].id === parseInt(productId, 10) || this.props.isAjaxCallInProgress}
        >
          &#9664;&nbsp;Back
        </Button>
        <Button
          classes={{ root: finalize ? classes.finalizeButton : null }}
          color="primary"
          variant="contained"
          onClick={this.handleNextClick}
          disabled={nextDisabled}
        >
          {this.props.isAjaxCallInProgress ? (
            <Fragment>Saving...</Fragment>
          ) : (
            <Fragment>
              {finalize ? (
                <Fragment>
                  Finalize&nbsp;
                  <b>&#10004;</b>
                </Fragment>
              ) : (
                <Fragment>Next&nbsp;&#9654;</Fragment>
              )}
            </Fragment>
          )}
        </Button>
        {variation === 'team' && (
          <ConfirmModal
            isOpen={this.state.openConfirmModal}
            checkNeeded={this.state.checkNeeded}
            handleConfirm={this.handleApprovedConfirmModal}
            handleCancel={this.handleCloseConfirmModal}
            titleConfirm={this.state.titleConfirmModal}
            textConfirmBtn={this.state.textConfirmBtnModal}
            textConfirm={this.state.textConfirmModal}
          />
        )}
      </div>
    )
  }
}

const actions = {
  submitOrder,
  updateProductImages,
  submit,
  blur
}

const selector = createStructuredSelector({
  locations: locationsSelector,
  instance: customizerInstanceSelector,
  products: productsSelector,
  user: userSelector
})

export default compose(
  withRouter,
  connect(
    state => ({
      ...selector(state),
      isAjaxCallInProgress: isAjaxCallInProgressSelector(state)
    }),
    actions
  ),
  withStyles(styles)
)(ProductHeader)
