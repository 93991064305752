export default theme => ({
  root: {
    overflow: 'hidden',
    marginBottom: theme.spacing.unit * 2
  },
  image: {
    width: '100%',
    height: 'auto'
  }
})
