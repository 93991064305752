export default theme => ({
  root: {
    position: 'relative',
    marginTop: '2rem'
  },
  noZone: {
    padding: '0 15%'
  },
  image: {
    width: '100%',
    height: 512,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
})
