import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class ProductsGrid extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired
  }

  render() {
    const { products, classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container spacing={24} justify="center" alignItems="center">
          {products.map(product => (
            <Grid key={product.id} item xs={4}>
              <img src={product.image.svg} alt={product.name} className={classes.image} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

export default compose(withStyles(styles))(ProductsGrid)
