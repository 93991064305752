import concat from 'lodash/concat'
import fp from 'lodash/fp'
import get from 'lodash/get'

export const filterRulesForSpec = (specId, rules = []) =>
  fp.filter(item => Number(item.listener.spec) === Number(specId))(rules)

const getEmbroideryColorValue = fp.compose(
  fp.get('color'),
  fp.find({ name: 'Embroidery' }),
  fp.get('zones.data')
)

const getSpecPool = (instance, spec) => {
  const products = fp.get('attributes.customizer.data.products.data')(instance)
  if (products) {
    for (let len = products.length, i = 0; i < len; i++) {
      const specs = fp.get('specs.data')(products[i])
      if (fp.find({ id: spec })(specs)) {
        return specs
      }
    }
  }
  return undefined
}

// const getLogosZones = (product) => {
//   const products = fp.get('attributes.customizer.data.products.data')(instance)
//   if (products) {
//     for (let len = products.length, i = 0; i < len; i++) {
//       const specs = fp.get('specs.data')(products[i])
//       if (fp.find({ id: spec })(specs)) {
//         return specs
//       }
//     }
//   }
//   return undefined
// }

export const validateRules = (value, allValues, props, name) => {
  const { products, rules } = props
  const specId = name.split('_')[1]
  const specRules = filterRulesForSpec(specId, rules)

  // Define `zone` variable for eval.
  // eslint-disable-next-line
  const zone = fp.compose(
    fp.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {}),
    zoneGroups => concat(...zoneGroups),
    fp.map(fp.get('zones.data'))
  )(products)
  for (const rule of specRules) {
    // Define `spec` variable for eval.
    // eslint-disable-next-line
    const spec = Object.keys(allValues).reduce((acc, key) => {
      acc[key.split('_')[1]] = allValues[key]
      return acc
    }, {})
    // eslint-disable-next-line
    const specsLength = Object.keys(allValues).reduce((acc, key) => {
      if(allValues[key]){
        acc[key.split('_')[1]] = allValues[key].toString().length
      }
      else{
        acc[key.split('_')[1]] = undefined
      }
      return acc
    }, {})

    const matches = rule.expression.match(/(spec_option\[\d+\])/g) || []
    // Define `spec_option` variable for eval.
    // eslint-disable-next-line
    const spec_option = matches.reduce((acc, item) => {
      const specOptionId = get(item.match(/\[(\d+)\]/), '[1]')
      acc[specOptionId] = specOptionId
      return acc
    }, {})
    try {
      // eslint-disable-next-line
      const result = eval(rule.expression)
      if (!result) {
        return rule.invalid
      }
    } catch (ex) {}
  }

  // On product zone sidebar
  return undefined
}

export const validateRulesRepeater = (value, allValues, props, name) => {
  const { products, rules, instance } = props
  const specId = name.split('_')[1]
  const specRules = filterRulesForSpec(specId, rules)
  //const repeater = name.split('_')[3]
  const specs = getSpecPool(instance, parseInt(specId, 10))
  const specObj = fp.find({ id: parseInt(specId, 10) })(specs)
  const keyAllValues = Object.keys(allValues)
  const keySpecs = Object.keys(specs)
  const specValuesByOption = []
  const specTotalByOption = []
  const specOptionsSelected = []

  if( process.env.REACT_APP_APP_DEBUG ){
    console.log('validateRulesRepeater props', props)
    console.log('validateRulesRepeater name', name)
    console.log('validateRulesRepeater products', products)
    console.log('validateRulesRepeater instance', instance)
    console.log('validateRulesRepeater specId', specId)
    console.log('validateRulesRepeater specRules', specRules)
    console.log('validateRulesRepeater specs', specs)
    console.log('validateRulesRepeater specObj', specObj)
    console.log('validateRulesRepeater allValues', allValues)
    console.log('validateRulesRepeater keyAllValues', keyAllValues)
    console.log('validateRulesRepeater keySpecs', keySpecs)
  }

  // Define `zone` variable for eval.
  // eslint-disable-next-line
  const zones = fp.compose(
    fp.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {}),
    zoneGroups => concat(...zoneGroups),
    fp.map(fp.get('zones.data'))
  )(products)

  if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater zones', zones)}

  // let haveLogoZone = false;
  // if (fp.find({ is_logo: true })(zones)) {
  //   haveLogoZone = true;
  // }
  // console.log('validateRulesRepeater haveLogoZone', haveLogoZone)
  //
  // const logosZones = fp.find({ is_logo: true })(zones);
  // console.log('validateRulesRepeater logosZones', logosZones)
  //
  // let haveLogoFile = false;
  // for (let len = logosZones.length, i = 0; i < len; i++) {
  //   console.log('validateRulesRepeater logosZones[i]', logosZones[i])
  //   if(logosZones[i].logo != undefined){
  //     haveLogoFile = true;
  //   }
  // }
  // console.log('validateRulesRepeater haveLogoFile', haveLogoFile)

  if(specObj.repeater && specObj.group && specRules){
    // eslint-disable-next-line
    const specsGroup = keySpecs.reduce((acc, key) => {
      if(specs[key].group === specObj.group && specs[key].id !== specObj.id){
        acc[key] = specs[key]
      }
      return acc
    }, [])
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specsGroup', specsGroup)}

    // const specsByGroupAndIndex = keyAllValues.reduce((acc, key) => {
    //   let specData = fp.find({ id: parseInt(key.split('_')[1], 10)})(specs)
    //   if(specData.repeater){
    //     if(acc[specData.group]){
    //       if(acc[specData.group][key.split('_')[3]]){
    //         acc[specData.group][key.split('_')[3]][key.split('_')[1]] = allValues[key]
    //       }else{
    //         acc[specData.group][key.split('_')[3]] = []
    //         acc[specData.group][key.split('_')[3]][key.split('_')[1]] = allValues[key]
    //       }
    //     }
    //     else{
    //       acc[specData.group] = []
    //       acc[specData.group][key.split('_')[3]] = []
    //       acc[specData.group][key.split('_')[3]][key.split('_')[1]] = allValues[key]
    //     }
    //   }
    //   return acc
    // }, {})

    const specsByGroupAndId = keyAllValues.reduce((acc, key) => {
      let specData = fp.find({ id: parseInt(key.split('_')[1], 10)})(specs)
      if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specData', specData)}
      if(specData && specData.repeater){
        if(acc[specData.group]){
          if(acc[specData.group][key.split('_')[1]]){
            acc[specData.group][key.split('_')[1]][key.split('_')[3]] = allValues[key]
          }else{
            acc[specData.group][key.split('_')[1]] = []
            acc[specData.group][key.split('_')[1]][key.split('_')[3]] = allValues[key]
          }
        }
        else{
          acc[specData.group] = []
          acc[specData.group][key.split('_')[1]] = []
          acc[specData.group][key.split('_')[1]][key.split('_')[3]] = allValues[key]
        }
      }
      return acc
    }, {})
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specsByGroupAndId', specsByGroupAndId)}

    const specsNotSelectGroup = keySpecs.reduce((acc, key) => {
      if(specs[key].group === specObj.group && specs[key].type !== 'select'){
        acc[key] = specs[key]
      }
      return acc
    }, [])
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specsNotSelectGroup', specsNotSelectGroup)}

    const specsSelectGroup = keySpecs.reduce((acc, key) => {
      if(specs[key].group === specObj.group && specs[key].type === 'select'){
        acc[key] = specs[key]
      }
      return acc
    }, [])
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specsSelectGroup', specsSelectGroup)}

    const specsNumberGroup = keySpecs.reduce((acc, key) => {
      if(specs[key].group === specObj.group && specs[key].type === 'number'){
        acc[key] = specs[key]
      }
      return acc
    }, [])
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specsNumberGroup', specsNumberGroup)}

    // const specValuesByOption = []
    // const specTotalByOption = []

    // const specOptionsSelected = []

    specsSelectGroup.forEach((specSelect) => {

      specValuesByOption[specSelect.id] = []
      specTotalByOption[specSelect.id] = []
      specOptionsSelected[specSelect.id] = []

      let specSelectData = fp.find({ id: specSelect.id})(specs)

      if(specSelectData){
        if(specSelectData.options){
          if(specSelectData.options.data){
            specSelectData.options.data.forEach((option) => {
              if(!specTotalByOption[specSelect.id][option.id]){
                specTotalByOption[specSelect.id][option.id] = []
              }
              specsNumberGroup.forEach((specNumber) => {
                  specTotalByOption[specSelect.id][option.id][specNumber.id] = 0
              })
              // specOptionsSelected[specSelect.id].push(option.id)

            })
          }

        }
      }

      if(specsByGroupAndId[specObj.group]){
        if(specsByGroupAndId[specObj.group][specSelect.id]){

          specsByGroupAndId[specObj.group][specSelect.id].map((optionId, keyOption) => {
            if(!specValuesByOption[specSelect.id][optionId]){
              specValuesByOption[specSelect.id][optionId] = []
            }
            if(!specTotalByOption[specSelect.id][optionId]){
              specTotalByOption[specSelect.id][optionId] = []
            }
            specOptionsSelected[specSelect.id].push(optionId)

            specsNotSelectGroup.forEach((spec) => {
              let value = ''
              if(spec.type === 'number'){
                value = 0
              }
              if(specsByGroupAndId[specObj.group][spec.id]){
                if(specsByGroupAndId[specObj.group][spec.id][keyOption]){
                  value = specsByGroupAndId[specObj.group][spec.id][keyOption]
                }
              }

              if(specValuesByOption[specSelect.id][optionId][spec.id]){
                specValuesByOption[specSelect.id][optionId][spec.id][keyOption] = value
              }
              else{
                specValuesByOption[specSelect.id][optionId][spec.id] = []
                specValuesByOption[specSelect.id][optionId][spec.id][keyOption] = value
              }
            })

            specsNumberGroup.forEach((specNumber) => {
              let value = 0
              if(specsByGroupAndId[specObj.group][specNumber.id]){
                if(specsByGroupAndId[specObj.group][specNumber.id][keyOption]){
                  value = specsByGroupAndId[specObj.group][specNumber.id][keyOption]
                }
              }
              if(specTotalByOption[specSelect.id][optionId][specNumber.id]){
                specTotalByOption[specSelect.id][optionId][specNumber.id] = specTotalByOption[specSelect.id][optionId][specNumber.id] + parseInt(value, 10)
              }
              else{
                specTotalByOption[specSelect.id][optionId][specNumber.id] = parseInt(value, 10)
              }
            })

          })
        }
      }
    })

    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specValuesByOption', specValuesByOption)}
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specTotalByOption', specTotalByOption)}
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater specOptionsSelected', specOptionsSelected)}

  }

  for (const rule of specRules) {

    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules - specOptionsSelected', specOptionsSelected)}

    // Define `spec` variable for eval.
    // eslint-disable-next-line
    const spec = keyAllValues.reduce((acc, key) => {
      if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules -  spec  - acc', acc)}
      if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules -  spec  - key', key)}
      let specData = fp.find({ id: parseInt(key.split('_')[1], 10)})(specs)
      if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules -  spec  - specData', specData)}
      if(specData){
        if(specData.repeater){
          acc[key.split('_')[1]+'_'+key.split('_')[3]] = allValues[key]
        }
        else{
          acc[key.split('_')[1]] = allValues[key]
        }
      }
      return acc
    }, {})
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules - spec', spec)}

    // eslint-disable-next-line
    const specsTotal = keyAllValues.reduce((acc, key) => {
      let specData = fp.find({ id: parseInt(key.split('_')[1], 10)})(specs)
      if(specData){
        if(specData.repeater){
          if(acc[key.split('_')[1]]){
            acc[key.split('_')[1]] = acc[key.split('_')[1]] + parseInt(allValues[key], 10);
          }
          else{
            acc[key.split('_')[1]] = parseInt(allValues[key], 10);
          }
        }
      }
      return acc
    }, {})
    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules - specsTotal', specsTotal)}

    const matches = rule.expression.match(/(spec_option\[\d+\])/g) || []

    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules - matches', matches)}

    // Define `spec_option` variable for eval.
    // eslint-disable-next-line
    const spec_option = matches.reduce((acc, item) => {
      const specOptionId = get(item.match(/\[(\d+)\]/), '[1]')
      acc[specOptionId] = specOptionId
      return acc
    }, {})

    if( process.env.REACT_APP_APP_DEBUG ){console.log('validateRulesRepeater  specRules - spec_option', spec_option)}

    try {
      // eslint-disable-next-line
      const result = eval(rule.expression)
      if (!result) {
        return rule.invalid
      }
    } catch (ex) {
      console.log('validateRulesRepeater ex', ex)
    }
  }

  // On product zone sidebar
  return undefined
}

export const validateQuantity = (value, allValues, props, name) => {
  const { instance } = props
  const spec = parseInt(name.split('_')[1], 10)
  const specs = getSpecPool(instance, spec)
  const nameSpec = name.split('_')[0]+'_'+name.split('_')[1]
  //const specObj = fp.find({ id: spec })(specs)

  if(name.split('_')[2] && name.split('_')[3] && name.split('_')[2] === 'r'){
      let groupIndex = name.split('_')[3]
      if (specs) {
        const noQty = specs
          .filter(spec => {
            const name = spec.name.toUpperCase()
            return spec.type === 'number' && (name.includes('QUANTITY') || name.includes('QTY'))
          })
          .every(value => !parseInt(allValues[name], 10))

        if (noQty) {
          if(allValues[nameSpec]){
            const noQty2 = specs
              .filter(spec => {
                const name = spec.name.toUpperCase()
                return spec.type === 'number' && (name.includes('QUANTITY') || name.includes('QTY'))
              })
              .every(value => !parseInt(allValues[nameSpec][groupIndex], 10))
            if (noQty2) {
              return 'At least one quantity should be provided'
            }
          }
          else{
            return 'At least one quantity should be provided'
          }
        }
      }
  }
  else{
      if (specs) {
        const noQty = specs
          .filter(spec => {
            const name = spec.name.toUpperCase()
            return spec.type === 'number' && (name.includes('QUANTITY') || name.includes('QTY'))
          })
          .every(value => !parseInt(allValues['spec_' + value.id], 10))
        if (noQty) {
          return 'At least one quantity should be provided'
        }
      }
  }
  return undefined
}

export const validateEmbroidery = (value, allValues, props, name) => {
  const { product, specOptions } = props
  const embroideryColor = getEmbroideryColorValue(product)
  if (specOptions) {
    if (value && !embroideryColor) {
      return 'You must choose a color for your embroidery'
    }
    if (!value && embroideryColor) {
      return 'You must provide embroidery next'
    }
  }
}

export const validateRequired = value => (value ? undefined : 'This field is required')

export const validateFloat = value => ( parseFloat(value, 10).toFixed(1) > 0 ? undefined : 'A value superior to 0 must be entered')

export const validateLogoDimension = (value, allValues, props, name) => {
  const { products } = props

  const zones = fp.compose(
    fp.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {}),
    zoneGroups => concat(...zoneGroups),
    fp.map(fp.get('zones.data'))
  )(products)

  if( process.env.REACT_APP_APP_DEBUG ){console.log('validateLogoDimension zones', zones)}
  let haveLogoZone = false;
  if (fp.find({ is_logo: true })(zones)) {
    haveLogoZone = true;
  }
  if( process.env.REACT_APP_APP_DEBUG ){console.log('validateLogoDimension haveLogoZone', haveLogoZone)}

  const logoZone = fp.find({ is_logo: true })(zones);
  if( process.env.REACT_APP_APP_DEBUG ){console.log('validateLogoDimension logoZone', logoZone)}

  let haveLogoFile = false;
  // for (let len = logosZones.length, i = 0; i < len; i++) {
  //   console.log('validateLogoDimension logosZones[i]', logosZones[i])
  //   if(logosZones[i].logo != undefined){
  //     haveLogoFile = true;
  //   }
  // }
  if(logoZone.logo != null){
    haveLogoFile = true;
  }
  if( process.env.REACT_APP_APP_DEBUG ){console.log('validateLogoDimension haveLogoFile', haveLogoFile)}
  if(haveLogoFile){
    if(parseFloat(value, 10).toFixed(1) > 0){
      return undefined
    }
    else{
      return 'You have a logo added, a value superior to 0 must be entered'
    }
  }
  else{
    if(parseFloat(value, 10).toFixed(1) > 0){
      return 'You have no logo added, the value must be 0'
    }
    else{
      return undefined
    }
  }

}
