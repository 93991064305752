import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Route } from 'react-router-dom'

import App from 'components/App'
import Home from 'pages/Home'
import Instance from 'pages/Instance'

const routes = ({ history }) => (
  <ConnectedRouter history={history}>
    <App>
      <Route path="/home" component={Home} />
      <Route path="/instance/:instanceId" component={Instance} />
      <Route exact path="/" component={Home} />
    </App>
  </ConnectedRouter>
)

export default routes
