import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class Menu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  render() {
    const { classes } = this.props
    const spyItems = ['finalize-save-order', 'finalize-submit-order']
    return (
      <div className={classes.root}>
        <Scrollspy items={spyItems} currentClassName="is-current" componentTag="div">
          <List component="nav">
            <ListItem button component="a" href="#finalize-save-order">
              <ListItemText primary="SAVE ORDER" />
            </ListItem>
            <ListItem button component="a" href="#finalize-submit-order">
              <ListItemText primary="SUBMIT ORDER" />
            </ListItem>
          </List>
        </Scrollspy>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Menu)
