import React, { Component } from 'react'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import CustomizerTitlebar from '../CustomizerTitlebar'
import ProductColorsTab from '../ProductColorsTab'
import ProductSpecsTab from '../ProductSpecsTab'
import styles, { TabStyle, TabsStyle } from './styles'
import { customizerInstanceSelector } from 'redux/modules/customizer/selectors'
import { propsChanged } from 'utils/helpers'
import {
  updateCustomizerProduct,
  updateCustomizerProductSpecs,
  deleteCustomizerProductSpecsGroup
} from 'redux/modules/customizer/actions'
import { actions as productLineActions, selectors } from 'redux/modules/productLine'

const Tab = withStyles(TabStyle)(MuiTab)
const Tabs = withStyles(TabsStyle)(MuiTabs)

class ProductSpecs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    productLine: PropTypes.object,
    initialValues: PropTypes.object,
    updateCustomizerProduct: PropTypes.func.isRequired,
    updateCustomizerProductSpecs: PropTypes.func.isRequired,
    deleteCustomizerProductSpecsGroup: PropTypes.func.isRequired,
    postHeightMessage: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'colors',
      colorsApproved: props.product.approved,
      hasError: false
    }
  }

  componentDidUpdate(prevProps) {
    if (propsChanged(['product.approved'], this.props, prevProps)) {
      this.setState({ colorsApproved: this.props.product.approved })
    }
    this.props.postHeightMessage()
  }

  handleChangeTab = (event, tabValue) => {
    const { variation } = this.props
    const { colorsApproved } = this.state
    if (variation === 'retail' && !colorsApproved && tabValue === 'specs') {
      this.setState({ hasError: true })
    } else {
      this.setState({ hasError: false })
      this.setState({ activeTab: tabValue })
    }
  }

  handleSetColorsApproved = colorsApproved => {
    const { instance, product, updateCustomizerProduct } = this.props
    this.setState({ colorsApproved })
    if (colorsApproved) {
      this.setState({ hasError: false })
    }
    updateCustomizerProduct({
      instanceId: instance.id,
      productId: product.id,
      data: { approved: colorsApproved }
    })
  }

  render() {
    const {
      instance,
      classes,
      product,
      updateCustomizerProductSpecs,
      deleteCustomizerProductSpecsGroup,
      variation,
      initialValues,
      postHeightMessage
    } = this.props
    const { activeTab, colorsApproved, hasError } = this.state

    let nameColorsTab = 'Colors';
    if(variation === 'team'){
      nameColorsTab = 'Colors / Logos';
    }

    return (
      <div id={`product-${product.id}`} className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs>
            <Grid container className={classes.tabBar}>
              <Grid item>
                <CustomizerTitlebar instance={instance} product={product} />
              </Grid>
              <Grid item className={classes.tabOverlap}>
                <Tabs
                  className={classes.tabs}
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleChangeTab}
                >
                  <Tab label={nameColorsTab} value="colors" />
                  <Tab label="Specs" value="specs" />
                </Tabs>
              </Grid>
            </Grid>
            <div className={cx({ [classes.hide]: activeTab !== 'colors' }, classes.tabBody)}>
              <ProductColorsTab
                product={product}
                hasError={hasError}
                setColorsApproved={this.handleSetColorsApproved}
                colorsApproved={colorsApproved}
                variation={variation}
              />
            </div>
            <div className={cx({ [classes.hide]: activeTab !== 'specs' }, classes.tabBody)}>
              <ProductSpecsTab
                instance={instance}
                product={product}
                initialValues={initialValues}
                postHeightMessage={postHeightMessage}
                updateCustomizerProductSpecs={updateCustomizerProductSpecs}
                deleteCustomizerProductSpecsGroup={deleteCustomizerProductSpecsGroup}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  productLine: selectors.productLineSelector,
  productLineState: selectors.productLineStateSelector,
  instance: customizerInstanceSelector
})

const actions = {
  updateCustomizerProduct,
  updateCustomizerProductSpecs,
  deleteCustomizerProductSpecsGroup,
  ...productLineActions
}

export default compose(
  connect(
    selector,
    actions
  ),
  withStyles(styles)
)(ProductSpecs)
