export default theme => ({
  root: {
    borderRadius: '0',
    boxShadow: 'none',
    backgroundImage: 'linear-gradient(to bottom right, rgb(234, 35, 48) , rgb(138, 20, 28))',
    '&:active': {
      boxShadow: 'none'
    }
  },

  disabled: {
    backgroundImage: 'none'
  }
})
