import { handleActions } from 'redux-actions'

import * as types from './types'

export default handleActions(
  {
    [types.PRODUCT_LINE_SELECT_PRODUCT]: (state, { payload }) => ({
      ...state,
      selectedProducts: payload
    }),

    [types.PRODUCT_LINE_SELECT_AGE_GROUP]: (state, { payload }) => ({
      ...state,
      ageGroup: payload
    }),

    [types.PRODUCT_LINE_SELECT_LINE]: (state, { payload }) => ({
      ...state,
      line: payload
    }),

    [types.PRODUCT_LINE_SELECT_LOCATION]: (state, { payload }) => ({
      ...state,
      location: payload
    }),

    [types.PRODUCT_LINE_SELECT_VARIATION]: (state, { payload }) => ({
      ...state,
      variation: payload
    }),

    [types.PRODUCT_LINE_SELECT_VERSION]: (state, { payload }) => ({
      ...state,
      version: payload
    })
  },
  {
    selectedProducts: [],
    ageGroup: null,
    location: null,
    line: 1,
    customizer: 1,
    version: 'tc',
    variation: 'pro'
  }
)
