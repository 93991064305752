import React, { Component } from 'react'
import cx from 'classnames'
import { compose } from 'redux'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import styles from '../SelectField/styles'

class InputField extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    input: PropTypes.object.isRequired,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    multiline: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    initialValue: PropTypes.string,
    hideErrorText: PropTypes.bool,
    rightAligned: PropTypes.bool,
    customize: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number
  }

  handleFocus = event => {
    const { onFocus } = this.props
    onFocus && onFocus(event)
  }

  render() {
    const {
      classes,
      className,
      disabled,
      fullWidth,
      input,
      inputProps,
      label,
      max,
      min,
      multiline,
      placeholder,
      type,
      hideErrorText,
      rightAligned,
      rows,
      customize,
      rowsMax,
      meta: { touched, error }
    } = this.props


    if(type === 'float'){
      const step = '0.01'
      //min = '0.01'

      return (
        <FormControl className={className} error={touched && !!error} fullWidth={fullWidth}>
          {label && <InputLabel>{label}</InputLabel>}
          <Input
            {...input}
            value={ Math.abs(parseFloat(input.value, 10).toFixed(1)) || 0 }
            onFocus={this.handleFocus}
            type={'number'}
            placeholder={placeholder}
            multiline={multiline}
            disabled={disabled}
            rows={rows}
            rowsMax={rowsMax}
            className={cx({ [classes.bordered]: customize })}
            classes={customize ? { input: classes.padding } : null}
            inputProps={{
              min,
              max,
              step,
              ...inputProps,
              className: cx({
                [get(inputProps, 'className')]: Boolean(get(inputProps, 'className')),
                'text-right': Boolean(rightAligned)
              })
            }}
          />
          {!hideErrorText && touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )
    }
    else{
      return (
        <FormControl className={className} error={touched && !!error} fullWidth={fullWidth}>
          {label && <InputLabel>{label}</InputLabel>}
          <Input
            {...input}
            value={type === 'number' ? Math.abs(parseInt(input.value, 10)) || 0 : input.value.toUpperCase()}
            onFocus={this.handleFocus}
            type={type}
            placeholder={placeholder}
            multiline={multiline}
            disabled={disabled}
            rows={rows}
            rowsMax={rowsMax}
            className={cx({ [classes.bordered]: customize })}
            classes={customize ? { input: classes.padding } : null}
            inputProps={{
              min,
              max,
              ...inputProps,
              className: cx({
                [get(inputProps, 'className')]: Boolean(get(inputProps, 'className')),
                'text-right': Boolean(rightAligned)
              })
            }}
          />
          {!hideErrorText && touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )
    }

  }
}

export default compose(withStyles(styles))(InputField)
