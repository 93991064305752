export default theme => ({
  root: {
    width: 400,
    height: '100%',
    flex: '0 1 auto',
    marginRight: theme.spacing.unit,
    backgroundColor: theme.palette.grey.A100,
    display: 'flex',
    flexDirection: 'column'
  }
})
