import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Main from 'components/Main'
import Content from 'components/Content'
import SidebarMenu from 'components/SidebarMenu'
import SidebarMenuHeading from 'components/SidebarMenu/SidebarMenuHeading'
import Menu from './components/Menu'
import ProductHeader from 'components/ProductHeader'
import SaveOrder from './components/SaveOrder'
import SubmitOrder from './components/SubmitOrder'
import styles from './styles'
import { customizerInstanceSelector } from 'redux/modules/customizer/selectors'
import { isAllColorsApproved } from 'utils/helpers'
import { productLineStateSelector } from 'redux/modules/productLine/selectors'

class FinalizePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    instance: PropTypes.object,
    productLineState: PropTypes.object.isRequired
  }

  render() {
    const {
      instance,
      match,
      productLineState: { variation }
    } = this.props
    const isValid =
      (variation === 'retail' && isAllColorsApproved(instance)) || variation !== 'retail'

    return isValid ? (
      <Main>
        <SidebarMenu>
          <SidebarMenuHeading>
            <div>{'Order Complete'}</div>
          </SidebarMenuHeading>
          <Menu />
        </SidebarMenu>
        <Content>
          <ProductHeader step="finalize" />
          <SaveOrder instance={instance} />
          <SubmitOrder instance={instance} />
        </Content>
      </Main>
    ) : (
      <Redirect to={`/instance/${match.params.instanceId}/specs`} />
    )
  }
}

const selector = createStructuredSelector({
  instance: customizerInstanceSelector,
  productLineState: productLineStateSelector
})

export default compose(connect(selector), withStyles(styles))(FinalizePage)
