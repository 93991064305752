import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import fp from 'lodash/fp'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Field, getFormValues, reduxForm } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'

import InputField from 'components/InputField'
import StoreSelectField from '../StoreSelectField'
import StoresMap from '../StoresMap'
import styles from './styles'
import TextSearchField from '../TextSearchField'
import { emailIsRequired, nameIsRequired, phoneIsRequired, storeIsRequired } from './validate'
import { formSubmit } from 'utils/form'
import { searchStores } from 'redux/modules/location/actions'
import { storesListSelector } from 'redux/modules/location/selectors'
import { submitOrder } from 'redux/modules/customizer/actions'

class SubmitOrderForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    instance: PropTypes.object.isRequired,
    searchStores: PropTypes.func.isRequired,
    storesList: PropTypes.array.isRequired
  }

  doSubmit = values => {
    const { instance, submitOrder } = this.props
    return formSubmit(submitOrder, {
      instanceId: instance.id,
      data: values
    })
  }

  handleSearchChange = value => {
    const { searchStores } = this.props
    searchStores({
      params: { q: value }
    })
  }

  render() {
    const { classes, formValues, handleSubmit, invalid, storesList } = this.props
    const storeName = fp.compose(
      fp.get('attributes.name'),
      fp.find({ id: fp.get('store')(formValues) })
    )(storesList)

    return (
      <form className={classes.root} onSubmit={handleSubmit(this.doSubmit)}>
        <Typography variant="body2" align="center" gutterBottom>
          USER INFO
        </Typography>
        <div className={classes.userInfo}>
          <Grid container spacing={40}>
            <Grid item xs={4}>
              <Field
                name="user.name"
                fullWidth
                component={InputField}
                label="Name"
                validate={[emailIsRequired]}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="user.phone"
                fullWidth
                component={InputField}
                label="Phone"
                validate={[nameIsRequired]}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="user.email"
                fullWidth
                type="email"
                component={InputField}
                label="Email"
                validate={[phoneIsRequired]}
              />
            </Grid>
          </Grid>
        </div>
        <Typography variant="body2" align="center" gutterBottom>
          CHOOSE A STORE
        </Typography>
        <Grid container spacing={40}>
          <Grid item xs={5}>
            <TextSearchField
              label="Search for a store by location or name"
              onChange={this.handleSearchChange}
            />
            <div className={classes.buttonWrapper}>
              <Button type="submit" color="primary" variant="raised" disabled={invalid}>
                Submit {storeName ? ` to ${storeName}` : ''}
              </Button>
            </div>
          </Grid>
          <Grid item xs={7}>
            <StoresMap options={storesList} />
          </Grid>
        </Grid>
        <Field
          name="store"
          label="Select a store below."
          component={StoreSelectField}
          validate={[storeIsRequired]}
          options={storesList}
        />
      </form>
    )
  }
}

const selector = createStructuredSelector({
  formValues: getFormValues('submitOrderForm'),
  storesList: storesListSelector
})

const actions = {
  searchStores,
  submitOrder
}

export default compose(
  connect(
    selector,
    actions
  ),
  reduxForm({
    form: 'submitOrderForm'
  }),
  withStyles(styles)
)(SubmitOrderForm)
