import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class Main extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  render() {
    const { classes, children } = this.props

    return <main className={classes.root}>{children}</main>
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Main)
