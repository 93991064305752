import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import registerServiceWorker from './registerServiceWorker'
import Routes from './routes'
import store, { history } from './redux/store'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import theme from './theme'
import './styles/main.css'

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <Routes history={history} />
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)
registerServiceWorker()
