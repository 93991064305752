import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

export default class TextSearchField extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.value || ''
    }
    this.timer = null
  }

  handleChange = event => {
    const { value } = event.target

    this.timer && clearTimeout(this.timer)
    this.setState({ value })
    if (value.length > 1) {
      this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
    }
  }

  handleKeyDown = event => {
    if (event.keyCode === ENTER_KEY) {
      this.timer && clearTimeout(this.timer)
      this.triggerChange()
    }
  }

  triggerChange = () => {
    const { value } = this.state

    this.props.onChange(value)
  }

  render() {
    const { label } = this.props
    return (
      <TextField
        fullWidth
        label={label}
        value={this.state.value}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
      />
    )
  }
}
