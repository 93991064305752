export default theme => ({
  root: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'black',
    color: 'white',
    maxHeight: '40px',
    alignItems: 'center',
    padding: '0 20px'
  },
  title: {
    padding: '15px 0',
    textTransform: 'capitalize'
  },
  dark: {
    backgroundColor: 'black',
    color: 'white'
  },
  instanceId: {
    textAlign: 'left'
  },
  specs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  specButton: {
    textTransform: 'none',
    fontWeight: 'normal',
    padding: `0 ${theme.spacing.unit}px`,
    minWidth: 0,
    minHeight: 24
    // display: 'inline-block',
  },

  finalizeButton: {
    backgroundImage: 'linear-gradient(to bottom right, rgb(64, 150, 53), rgb(38, 97, 31))'
  }
})

export const NavigationStyles = theme => ({
  root: {
    borderRadius: '0',
    color: 'white',
    marginLeft: '2px',
    minHeight: '24px',
    minWidth: '88px',
    fontSize: '0.8rem',
    padding: '0',
    margin: '0 7px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})

export const TabsStyles = theme => ({
  root: {
    minHeight: '40px',
    padding: '0 30px'
  },
  indicator: {
    opacity: '0'
  }
})

export const TabStyles = theme => ({
  root: {
    minWidth: '100px',
    opacity: '1',
    backgroundColor: 'black',
    color: 'white',
    height: '40px',
    minHeight: '40px'
  },
  selected: {
    backgroundColor: '#444'
  },
  label: {
    fontSize: '1rem',
    textTransform: 'capitalize'
  }
})
