import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'

import LoadingIndicator from 'components/LoadingIndicator'
import {
  customizerInstanceSelector,
  isInstanceLoadingSelector,
  isInstanceRejectedSelector
} from 'redux/modules/customizer/selectors'
import { getCustomizerInstance } from 'redux/modules/customizer/actions'
import { propsChanged } from 'utils/helpers'

export default WrappedComponent => {
  class InstanceWrapper extends Component {
    componentDidMount() {
      this.loadInstance(this.props)
    }

    componentDidUpdate(prevProps) {
      if (propsChanged(['instanceId', 'match.params.instanceId'], this.props, prevProps)) {
        this.loadInstance(this.props)
      }
    }

    loadInstance(props) {
      const { customizerInstance, getCustomizerInstance, match } = props
      const instanceId = props.instanceId || match.params.instanceId
      if (!customizerInstance || instanceId !== customizerInstance.id) {
        getCustomizerInstance({ id: instanceId })
      }
    }

    render() {
      const { customizerInstance, isInstanceRejected } = this.props

      return customizerInstance ? (
        <WrappedComponent {...this.props} customizerInstance={customizerInstance} />
      ) : isInstanceRejected ? (
        <Redirect to="/" />
      ) : (
        <LoadingIndicator />
      )
    }
  }

  const selector = createStructuredSelector({
    customizerInstance: customizerInstanceSelector,
    isInstanceLoading: isInstanceLoadingSelector,
    isInstanceRejected: isInstanceRejectedSelector
  })

  const actions = {
    getCustomizerInstance
  }

  return compose(
    withRouter,
    connect(
      selector,
      actions
    )
  )(InstanceWrapper)
}
