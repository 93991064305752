import { fade } from '@material-ui/core/styles/colorManipulator'

export default theme => ({
  root: {
    overflow: 'hidden',
    height: '100%'
  },
  content: {
    padding: '2rem 4rem',
    position: 'relative'
  },
  errorMessage: {
    borderRadius: 5,
    padding: '0.5rem',
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.1)
  }
})
