import { SubmissionError } from 'redux-form'

export const formSubmit = (actionCreator, payload) => {
  return new Promise((resolve, reject) => {
    actionCreator({ ...payload, resolve, reject })
  }).catch(ex => {
    const res = ex.response ? ex.response : ex
    const errors = res.data || res.error || res // TODO: assumes backend response are wrapped within data or error field
    if (typeof errors === 'object') {
      const { message, ...fieldErrors } = errors

      throw new SubmissionError({
        _error: message,
        ...fieldErrors
      })
    } else {
      throw new SubmissionError({
        _error: 'Internal Server Error'
      })
    }
  })
}
