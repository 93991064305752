import fp from 'lodash/fp'
import isEqual from 'lodash/isEqual'

export const propsChanged = (propKeys, props, nextProps, deepCompare = false) => {
  if (deepCompare) {
    for (let key of propKeys) {
      if (!isEqual(fp.get(key)(props), fp.get(key)(nextProps))) {
        return true
      }
    }
  } else {
    for (let key of propKeys) {
      if (fp.get(key)(props) !== fp.get(key)(nextProps)) {
        return true
      }
    }
  }
  return false
}

export const parseQueryString = string =>
  fp.compose(
    JSON.parse,
    JSON.stringify,
    fp.reduce((acc, part) => {
      const [name, value] = part.split('=')
      acc[name] = decodeURIComponent(value || '')
      return acc
    }, {}),
    str => (str ? str.split('&') : []),
    fp.replace('?', '')
  )(string)

export const jsonToQueryString = obj => {
  const pairs = []
  obj &&
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        const value = encodeURIComponent(obj[key])
        value && pairs.push(`${key}=${value}`)
      }
    })

  return pairs.length ? `?${pairs.join('&')}` : ''
}

export const isAllColorsApproved = instance =>
  fp.compose(
    fp.isEqual(0),
    fp.size,
    fp.filter({ approved: false }),
    fp.defaultTo([]),
    fp.get('attributes.products.data')
  )(instance)

export const productSteps = [
  {
    key: 'intro',
    label: 'Intro'
  },
  {
    key: 'specs',
    label: 'Specs'
  },
  {
    key: 'finalize',
    label: 'Finalize'
  },
  {
    key: 'done',
    label: 'Done'
  }
]

export const getSpecsValues = instance =>
  fp.compose(
    fp.reduce((acc, item) => {
      if(!item.repeater){
        acc[`spec_${item.id}`] = item.type === 'select' ? item.value.option_id : item.value.input
      }
      else{
        acc[`spec_${item.id}_r_${item.value.group_index}`] = item.type === 'select' ? item.value.option_id : item.value.input
      }
      return acc
    }, {}),
    fp.get('attributes.specs.data')
  )(instance)

export const isEmpty = obj => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
