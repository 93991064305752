import { createAction } from 'redux-actions'

import * as types from './types'

export const searchCustomizers = createAction(types.SEARCH_CUSTOMIZERS)

export const createCustomizerInstance = createAction(types.CREATE_CUSTOMIZER_INSTANCE)

export const getCustomizerInstance = createAction(types.GET_CUSTOMIZER_INSTANCE)

export const getUser = createAction(types.GET_USER)

export const matchProduct = createAction(types.MATCH_PRODUCT)

export const setColorsApproved = createAction(types.SET_COLORS_APPROVED)

export const updateCustomizerProduct = createAction(types.UPDATE_CUSTOMIZER_PRODUCT)

export const updateCustomizerProductSpecs = createAction(types.UPDATE_CUSTOMIZER_PRODUCT_SPECS)

export const deleteCustomizerProductSpecsGroup = createAction(
  types.DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP
)

export const updateInstanceZoneColor = createAction(types.UPDATE_INSTANCE_ZONE_COLOR)

export const updateInstanceZoneLogo = createAction(types.UPDATE_INSTANCE_ZONE_LOGO)

export const deleteInstanceZoneLogo = createAction(types.DELETE_INSTANCE_ZONE_LOGO)

export const updateInstanceProductOption = createAction(types.UPDATE_INSTANCE_PRODUCT_OPTION)

export const updateProductImages = createAction(types.UPDATE_PRODUCT_IMAGES)

export const submitOrder = createAction(types.SUBMIT_ORDER)

export const AJAX_REQUEST_START = 'AJAX_REQUEST_START'
export const AJAX_REQUEST_END = 'AJAX_REQUEST_END'

export const ajaxRequestStart = () => ({ type: AJAX_REQUEST_START })
export const ajaxRequestEnd = () => ({ type: AJAX_REQUEST_END })
