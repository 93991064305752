export default theme => ({
  minorFont: {
    fontSize: '0.8rem'
  },
  selectedColor: {
    display: 'flex',
    margin: '0.5rem 1rem',
    padding: '5px 10px',
    alignItems: 'center',
    border: '1px solid lightgray'
  },
  bold: {
    margin: '0 5px',
    fontWeight: 'bolder'
  }
})
