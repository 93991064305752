export default theme => ({
  root: {
    padding: '3rem',
    border: '1px solid lightgray'
  },
  zoneColor: {
    width: 18,
    height: 18,
    boxShadow: '0 0 4px 1px rgba(0,0,0,0.1)',
    border: '2px solid white',
    borderRadius: '50%'
  },
  hr: {
    borderWidth: 0.7,
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[300]
  },
  colorName: {
    textAlign: 'left'
  },
  zoneLogo: {
    textAlign: 'left'
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '400px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom: '10px'
  },
  logo: {
    width: '100%',
    height: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  toolbar: {
    padding: '5px',
    border: '1px solid lightgray',
    marginTop: '30px'
  },
  imageBox: {
    textAlign: 'center'
  },
  imageBtnDdl: {
    marginTop: '20px'
  }
})
