import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, isInvalid } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Main from 'components/Main'
import Content from 'components/Content'
import ProductHeader from 'components/ProductHeader'
import ProductSpecs from './components/ProductSpecs'
import ProductsList from './components/ProductsList'
import styles from './styles'
import withQueryParamsHandler from 'hocs/withQueryParamsHandler'
import {
  customizerInstanceSelector,
  productsSelector,
  rulesSelector
} from 'redux/modules/customizer/selectors'
import { isAllColorsApproved } from 'utils/helpers'
import { productLineStateSelector } from 'redux/modules/productLine/selectors'
import { getSpecsValues } from 'utils/helpers'

class SpecsPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    productLineState: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    products: PropTypes.array,
    rules: PropTypes.array
  }

  constructor(props) {
    super(props)
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  postHeightMessage(height) {
    if (this.content && this.pageHeight !== this.content.scrollHeight) {
      this.pageHeight = this.content.scrollHeight
      window.parent && window.parent.postMessage(`{"contentHeight": ${this.pageHeight + 40}}`, '*')
    }
  }

  componentDidUpdate() {
    if (!this.props.initialized && this.props.initialValues) {
      this.props.initialize(this.props.initialValues)
    }
  }

  handleContentRef = ref => {
    this.content = ref
  }

  handleWindowResize = e => {
    this.postHeightMessage()
  }


  render() {
    const {
      classes,
      instance,
      productLineState,
      products,
      handleSubmit,
      submitFailed,
      initialValues,
      invalid
    } = this.props
    const isFormInvalid = false
    const { variation } = productLineState
    const nextDisabled = variation === 'retail' && !isAllColorsApproved(instance)

    return (
      <div className={classes.root}>
        <ProductHeader step="specs" nextDisabled={nextDisabled || isFormInvalid} variation={variation}/>
        <Main>
          <Content domRef={this.handleContentRef} className={classes.content}>
            <ProductsList options={products} instance={instance} />
            {submitFailed &&
              invalid && (
                <div className={classes.errorMessage}>
                  <Typography color="error" variant="body2" align="center">
                    Improper values are provided in spec page, please check them out.
                  </Typography>
                </div>
              )}
            <form onSubmit={handleSubmit}>
              {products.map(product => (
                <ProductSpecs
                  key={product.id}
                  product={product}
                  instance={instance}
                  variation={variation}
                  initialValues={initialValues}
                  postHeightMessage={this.postHeightMessage.bind(this)}
                />
              ))}
            </form>
          </Content>
        </Main>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  isFormInvalid: isInvalid('productSpecsForm'),
  instance: customizerInstanceSelector,
  productLineState: productLineStateSelector,
  products: productsSelector,
  rules: rulesSelector,
  initialValues: compose(
    getSpecsValues,
    customizerInstanceSelector
  )
})

export default compose(
  withQueryParamsHandler,
  connect(selector),
  reduxForm({
    form: 'productSpecsForm',
    destoryOnUnmount: false,
    forceUnregisterOnUnmount: true
    // enableReinitialize: true
  }),
  withStyles(styles)
)(SpecsPage)
