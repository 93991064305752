import React, { Component } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'

import LoadingIndicator from 'components/LoadingIndicator'
import Finalize from 'pages/Finalize'
import Product from 'pages/Product'
import Specs from 'pages/Specs'
import withInstance from 'hocs/withInstance'
import withQueryParamsHandler from 'hocs/withQueryParamsHandler'
import { userSelector } from 'redux/modules/customizer/selectors'
import { submitOrder } from 'redux/modules/customizer/actions'

class InstancePage extends Component {
  static propTypes = {
    customizerInstance: PropTypes.object,
    match: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      lastZone: null,
      isSubmitting: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('Instance componentWillReceiveProps nextProps', nextProps)
      console.log('Instance componentWillReceiveProps Props', this.props)
    }
  }

  saveLastZone = lastZone => {
    this.setState({ lastZone })
  }

  handleSpecsSubmit = () => {
    const {
      user,
      history,
      submitOrder,
      customizerInstance,
      match: {
        params: { instanceId }
      }
    } = this.props

    const { isSubmitting } = this.state

    if (isSubmitting) {
      return
    }

    console.log('Instance handleSpecsSubmit user', user)
    if (user && user.id && window.parent) {
      const submitted = get(customizerInstance, 'attributes.submitted')
      const variation = get(customizerInstance, 'attributes.customizer.data.variation')
      console.log('Instance handleSpecsSubmit submitted', submitted)
      console.log('Instance handleSpecsSubmit customizerInstance', customizerInstance)
      console.log(
        'Instance handleSpecsSubmit customizerInstance.attributes',
        customizerInstance.attributes
      )
      console.log(
        'Instance handleSpecsSubmit customizerInstance.attributes.customizer',
        customizerInstance.attributes.customizer
      )
      console.log(
        'Instance handleSpecsSubmit customizerInstance.attributes.customiser.data',
        customizerInstance.attributes.customizer.data
      )
      console.log('Instance handleSpecsSubmit Props', this.props)
      console.log('Instance handleSpecsSubmit variation', variation)
      console.log('Instance handleSpecsSubmit instanceId', instanceId)
      if (submitted) {
        if (variation == 'retail') {
          window.parent.postMessage(
            '{"submitted": true, "variation": "retail", "instanceId": "' + instanceId + '"}',
            '*'
          )
        } else {
          window.parent.postMessage('{"submitted": true, "instanceId": "' + instanceId + '"}', '*')
        }
      } else {
        this.setState({ isSubmitting: true })

        submitOrder({
          instanceId,
          data: {
            user: { email: get(user, 'attributes.email') }
          },
          success: () => {
            window.parent.postMessage(
              '{"submitted": true, "instanceId": "' + instanceId + '"}',
              '*'
            )
            this.setState({ isSubmitting: false }) // Reset isSubmitting on success
          },
          // Assuming a failure case or error handling function exists
          failure: () => {
            // Handle failure, possibly show a message
            console.log('Submit order failed')
            this.setState({ isSubmitting: false }) // Reset isSubmitting on failure
          }
        })
      }
    } else {
      history.push(`/instance/${instanceId}/finalize`)
    }
  }

  product = props => (
    <Product {...props} lastZone={this.state.lastZone} saveLastZone={this.saveLastZone} />
  )

  specs = props => <Specs {...props} onSubmit={this.handleSpecsSubmit} />

  render() {
    const { customizerInstance, match } = this.props
    const { isSubmitting } = this.state
    const productId = get(customizerInstance, 'attributes.products.data[0].id')
    return (
      <Switch>
        {isSubmitting && <LoadingIndicator />}
        <Route exact path={`${match.path}/product/:productId`} render={this.product} />
        <Route exact path={`${match.path}/specs`} render={this.specs} />
        <Route exact path={`${match.path}/finalize`} component={Finalize} />
        <Redirect to={`${match.url}/product/${productId}`} />
      </Switch>
    )
  }
}

const actions = {
  submitOrder
}

const selector = createStructuredSelector({
  user: userSelector
})

export default compose(
  withInstance,
  withQueryParamsHandler,
  withRouter,
  connect(selector, actions)
)(InstancePage)

// export default withInstance(InstancePage)
