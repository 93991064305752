import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import styles, { buttonStyles } from './styles'

const StoreItem = withStyles(buttonStyles)(Button)

class StoreSelectField extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired
  }

  handleChange = value => () => {
    const { input } = this.props
    input.onChange(value)
  }

  handleBlur = value => () => {
    const { input } = this.props
    input.onBlur(value)
  }

  render() {
    const { classes, input, label, options } = this.props

    return (
      <div className={classes.root}>
        <Typography variant="body2">{label}</Typography>
        <Grid container spacing={8} justify="center" alignItems="stretch">
          {options.map(item => (
            <Grid item xs={3} key={item.id} className={classes.item}>
              <StoreItem
                className={cx({ [classes.storeItemSelected]: input.value === item.id })}
                fullWidth
                variant="outlined"
                onClick={this.handleChange(item.id)}
                onBlur={this.handleBlur(item.id)}
              >
                <Typography variant="body2" gutterBottom>
                  {item.attributes.name}
                </Typography>
                <Typography variant="caption">{item.attributes.address}</Typography>
              </StoreItem>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

export default compose(withStyles(styles))(StoreSelectField)
