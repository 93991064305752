import { createMuiTheme } from '@material-ui/core/styles'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

export default createMuiTheme({
  palette: {
    primary: red,
    secondary: indigo,
    success: green,
    background: {
      default: '#fff'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em"
      }
    }
  }
})
