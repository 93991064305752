import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { customizerInstanceSelector } from 'redux/modules/customizer/selectors'


class SelectProductOption extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object,
    pOption: PropTypes.object,
    selectProductOption: PropTypes.func.isRequired,
    productOptions: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selected: props.pOption
    }

    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('SelectProductOption constructor product', props.product)
    }
  }

  componentDidMount() {
    const { pOption } = this.props
    if (this.state.selected !== pOption) {
      this.setState({ selected: pOption })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('SelectProductOption componentWillReceiveProps nextProps', nextProps)
      console.log('SelectProductOption componentWillReceiveProps Props', this.props)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('SelectProductOption componentDidUpdate prevProps', prevProps)
      console.log('SelectProductOption componentDidUpdate prevState', prevState)
      console.log('SelectProductOption componentDidUpdate state', this.state)
      console.log('SelectProductOption componentDidUpdate props', this.props)
    }
    const { pOption } = this.props
    if (this.state.selected !== pOption) {
      this.setState({ selected: pOption })
    }
  }

  handleSelectOption = pOption => () => {
    if (this.state.selected !== pOption) {
      this.setState({ selected: pOption })
    }
    this.props.selectProductOption(pOption)
  }

  render() {
    const { classes, product, productOptions } = this.props
    const { selected } = this.state

    return (
      <div className={classes.root}>
        <Grid container spacing={0} alignItems="center" className={classes.container}>
          <Grid item className={classes.titleBox}>
            <Typography className={classes.title}>Select {product.label_options}</Typography>
          </Grid>
          { productOptions && productOptions.map((item, key) => (
            <Grid item className={classes.optionBox}>
              <ButtonBase
                focusRipple
                key={item.slug}
                className={ selected.id === item.id ? classes.optionSelected : classes.option}
                focusVisibleClassName={classes.focusOption}
                onClick={this.handleSelectOption(item)}
              >
                <Typography className={classes.optionName}>{item.name}</Typography>
                <Typography className={classes.optionDesc}>{item.description}</Typography>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  instance: customizerInstanceSelector
})

export default compose(
  connect(
    selector
  ),
  withStyles(styles)
)(SelectProductOption)
