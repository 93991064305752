import fp from 'lodash/fp'
import { selectors } from '../api'

export const productLineStateSelector = fp.get('productLine')

export const isProductLineLoadingSelector = selectors.isRequestPending('productLine', 'get')

export const isProductLineLoadedSelector = selectors.isRequestSuccess('productLine', 'get')

export const productLineSelector = selectors.dataSelector('productLine')
