import React, { Component } from 'react'
import Content from 'components/Content'
import cx from 'classnames'
import find from 'lodash/find'
import fp from 'lodash/fp'
import get from 'lodash/get'
import LoadingIndicator from 'components/LoadingIndicator'
import Main from 'components/Main'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getFormSyncErrors, getFormMeta, getFormValues, reduxForm } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'

import ProductDetail from './components/ProductDetail'
import ProductHeader from 'components/ProductHeader'
import PropTypes from 'prop-types'
import SelectZone from './components/SelectZone'
import MatchProduct from './components/MatchProduct'
import SidebarMenu from 'components/SidebarMenu'
import SpecField from 'components/SpecField'
import SelectProductOption from './components/SelectProductOption'
import styles from './styles'
import {
  customizerInstanceSelector,
  customizerSpecOptionsSelector,
  isMatchingProductSelector,
  isProductImagesSavingSelector,
  productsSelector,
  rulesSelector,
  zoneOptionsSelector,
  customizerProductOptionsSelector
} from 'redux/modules/customizer/selectors'
import { getSpecsValues } from 'utils/helpers'
import {
  updateInstanceZoneColor,
  updateInstanceZoneLogo,
  deleteInstanceZoneLogo,
  updateProductImages,
  updateInstanceProductOption,
  updateCustomizerProductSpecs
} from 'redux/modules/customizer/actions'
import { validateRules } from './validate'
import { validateRequired, validateRules as validateEmbRules } from 'pages/Specs/validate'

const getFormErrorText = formErrors => {
  const keys = Object.keys(formErrors)
  return keys.length ? keys.map(key => formErrors[key]).concat(' ') : ''
}

const getEmbroideryValue = (specs, specValues) =>
  fp.compose(
    id => (id ? get(specValues, `spec_${id}`) : undefined),
    fp.get('id'),
    fp.find({ slug: 'embroidery_name' })
  )(specs)

class ProductPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object,
    isMatchingProduct: PropTypes.bool,
    isProductImagesSaving: PropTypes.bool,
    match: PropTypes.object.isRequired,
    products: PropTypes.array,
    rules: PropTypes.array,
    specs: PropTypes.array,
    lastZone: PropTypes.object,
    customLogos: PropTypes.array,
    customAiFilesLogos: PropTypes.array,
    saveLastZone: PropTypes.func,
    updateInstanceZoneColor: PropTypes.func.isRequired,
    updateInstanceZoneLogo: PropTypes.func.isRequired,
    deleteInstanceZoneLogo: PropTypes.func.isRequired,
    updateInstanceProductOption: PropTypes.func,
    updateCustomizerProductSpecs: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedZone: null,
      hoverZone: null,
      selectedColor: null,
      prevProductId: null,
      lastZone: null,
      globalError: null, // store validation result for whole zone rules not only for currently selected zone
      error: null,
      customLogos: [],
      customAiFilesLogos: [],
      selectedProductOption: null,
      productOptionMessage: null
    }
    window.addEventListener('resize', this.handleWindowResize)
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage constructor Props', this.props)
      console.log('ProductPage constructor State', this.state)
    }
  }

  componentDidMount() {
    this.setState({ lastZone: this.props.lastZone })
    this.postHeightMessage()
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage componentDidMount Props', this.props)
      console.log('ProductPage componentDidMount State', this.state)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage componentDidUpdate prevProps', prevProps)
      console.log('ProductPage componentDidUpdate prevState', prevState)
      console.log('ProductPage componentDidUpdate Props', this.props)
      console.log('ProductPage componentDidUpdate State', this.state)
    }
    const { productId } = this.props.match.params
    const { lastZone, selectedZone, selectedProductOption } = this.state
    const { zoneOptions, productOptions, product } = this.props

    // if( prevProps.product.id != product.id ){
    //   this.setState({
    //     selectedProductOption: null
    //   })
    // }

    if (zoneOptions !== prevProps.zoneOptions && selectedZone) {
      const zone = fp.find({ id: selectedZone.id })(zoneOptions)
      if (zone) {
        this.handleSelectZone(zone)
        //return
      } else {
        this.handleSelectZone(this.props.zoneOptions[0])
      }
    } else if (productId !== prevState.prevProductId) {
      if (lastZone && productId in lastZone) {
        if (lastZone[productId]) {
          this.handleSelectZone(lastZone[productId])
          //return
        } else {
          this.handleSelectZone(this.props.zoneOptions[0])
        }
      } else {
        this.handleSelectZone(this.props.zoneOptions[0])
      }
    }

    //if (productOptions && productId !== prevState.prevProductId && !selectedProductOption && product.option_id ) {
    //if (productOptions && productId !== prevState.prevProductId && product.option_id && ( !selectedProductOption || (product.option_id != selectedProductOption.id)) ) {
    if (
      productOptions &&
      product.option_id &&
      (!selectedProductOption || product.option_id !== selectedProductOption.id)
    ) {
      const pOption = fp.find({ id: product.option_id })(productOptions)
      this.handleSelectProductOption(pOption)
    }
    //  this.postHeightMessage()
  }

  validateForAllZones() {
    const { product, rules } = this.props
    const zones = get(product, 'zones.data', [])

    for (let i = 0, len = zones.length; i < len; i++) {
      const zone = zones[i]
      const error = validateRules(zone.id, zone.color, zones, rules)
      if (error) {
        if (error.type === 'zone') {
          return (
            zone.name +
            " can't select that color because " +
            error.target.name +
            ' has the same color already selected.' +
            '<br>Please select another color.'
          )
        }
        return error
      }
    }
  }

  saveLastZone = () => this.props.saveLastZone(this.state.lastZone)

  postHeightMessage() {
    if (this.content && this.pageHeight !== this.content.scrollHeight) {
      this.pageHeight = this.content.scrollHeight
      window.parent && window.parent.postMessage(`{"contentHeight": ${this.pageHeight + 40}}`, '*')
    }
  }

  handleContentRef = ref => {
    this.content = ref
  }

  handleWindowResize = e => {
    this.postHeightMessage()
  }

  handleSelectZone = zone => {
    const { productId } = this.props.match.params
    this.setState({
      selectedZone: zone,
      selectedColor: zone ? zone.color : null,
      prevProductId: productId,
      error: null,
      globalError: this.validateForAllZones(),
      lastZone: { ...this.state.lastZone, [productId]: zone }
    })
  }

  handleSelectProductOption = productOption => {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage handleSelectProductOption productOption', productOption)
      console.log('ProductPage handleSelectProductOption props', this.props)
      console.log('ProductPage handleSelectProductOption state', this.state)
    }
    const { instance, product, updateInstanceProductOption, zoneOptions } = this.props
    const { selectedProductOption } = this.state
    const variation = get(instance, 'attributes.customizer.data.variation')
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage handleSelectProductOption zoneOptions', zoneOptions)
    }
    if (
      selectedProductOption != null &&
      selectedProductOption !== productOption &&
      product.option_id !== productOption.id
    ) {
      console.log('ProductPage handleSelectProductOption updateInstanceProductOption')
      updateInstanceProductOption({
        instanceId: instance.id,
        productId: product.id,
        data: { optionId: productOption.id }
      })
    }
    if (variation == 'retail') {
      this.setState({
        selectedProductOption: productOption,
        productOptionMessage: productOption.msg_retail
      })
    } else {
      this.setState({
        selectedProductOption: productOption
      })
    }

    let zonesBoxSvg = document.querySelector('.product-svg #Zones')
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage handleSelectProductOption zonesBoxSvg', zonesBoxSvg)
    }

    if (zonesBoxSvg) {
      zoneOptions.map((zone, key) => {
        if (zone.is_option) {
          let zoneSvg = document.querySelector('.product-svg #Zones #' + zone.slug)
          if (process.env.REACT_APP_APP_DEBUG) {
            console.log('ProductPage handleSelectProductOption zoneSvg', zoneSvg)
          }
          if (zoneSvg) {
            if (zone.option_id === productOption.id) {
              zoneSvg.style.display = 'inline'
            } else {
              zoneSvg.style.display = 'none'
            }
          }
        }
      })
    } else {
      setTimeout(() => {
        zoneOptions.map((zone, key) => {
          if (zone.is_option) {
            let zoneSvg = document.querySelector('.product-svg #Zones #' + zone.slug)
            if (process.env.REACT_APP_APP_DEBUG) {
              console.log('ProductPage handleSelectProductOption zoneSvg', zoneSvg)
            }
            if (zoneSvg) {
              if (zone.option_id === productOption.id) {
                zoneSvg.style.display = 'inline'
              } else {
                zoneSvg.style.display = 'none'
              }
            }
          }
        })
      }, 1000)
    }
  }

  handleHoverZone = zone => () => this.setState({ hoverZone: zone })

  handleUnhoverZone = () => this.setState({ hoverZone: null })

  handleSpecChange = (event, newValue, prevValue, name) => {
    const { instance, updateCustomizerProductSpecs } = this.props
    const specId = name.split('_')[1]
    updateCustomizerProductSpecs({
      instanceId: instance.id,
      specId,
      data: { input: newValue }
    })
  }

  handleSelectColor = color => {
    const {
      rules,
      product,
      instance,
      updateInstanceZoneColor,
      match: {
        params: { productId }
      }
    } = this.props
    const { selectedZone } = this.state
    const zones = get(product, 'zones.data', [])
    const error = selectedZone && validateRules(selectedZone.id, color, zones, rules)

    if (error && error.type === 'zone') {
      this.setState({
        error:
          selectedZone.name +
          " can't select that color because " +
          error.target.name +
          ' has the same color already selected.' +
          '<br>Please select another color.'
      })
    } else {
      const { lastZone, selectedZone } = this.state
      this.props.updateInstanceZoneColor({
        instanceId: instance.id,
        zoneId: selectedZone.id,
        data: { color: color.id }
      })
      this.setState({
        selectedColor: color,
        error: null,
        lastZone: {
          ...lastZone,
          [productId]: { ...selectedZone, color: color }
        }
      })
    }
  }

  handleClearZone = () => {
    const { productId } = this.props.match.params
    this.setState({
      selectedZone: null,
      selectedColor: null,
      error: null,
      lastZone: { ...this.state.lastZone, [productId]: null }
    })
  }

  handleSubmitCustomLogo = (zone, logo, aiFile) => {
    const { instance, updateInstanceZoneLogo } = this.props
    const { customLogos, customAiFilesLogos } = this.state
    const formData = new FormData()

    if (logo) {
      formData.append('logo', logo.file, logo.filename)
      customLogos[zone] = { file: logo, saved: true }
    }

    if (aiFile) {
      formData.append('aiFile', aiFile.file, aiFile.filename)
      customAiFilesLogos[zone] = { file: aiFile, saved: true }
    }

    updateInstanceZoneLogo({
      instanceId: instance.id,
      zoneId: this.state.selectedZone.id,
      data: formData
    })

    this.setState({ customLogos: customLogos, customAiFilesLogos: customAiFilesLogos })
  }

  handleDeleteCustomLogo = zone => {
    const { instance, deleteInstanceZoneLogo } = this.props

    deleteInstanceZoneLogo({
      instanceId: instance.id,
      zoneId: this.state.selectedZone.id
    })
  }

  handleCustomLogo = (zone, action, typeFile = 'png', file = null) => {
    const { customLogos, customAiFilesLogos } = this.state
    if (typeFile === 'png') {
      if (action === 'add') {
        let imgbase64 = file.getFileEncodeBase64String()
        let imgbase64Str = 'data:image/png; base64,' + imgbase64
        //let pattern = document.querySelector('.product-svg svg defs #pattern-'+zone);
        let image = document.querySelector(
          '.product-svg svg defs #pattern-' + zone + ' #patimg-' + zone
        )
        let xlinkImage = image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
        if (xlinkImage !== imgbase64Str) {
          image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgbase64Str)
        }
        let zoneCustomlogo = document.querySelector('.product-svg #Zones #' + zone)
        zoneCustomlogo.querySelector('.use-logo').style.fill = 'url(#pattern-' + zone + ')'

        let zoneCustomlogoDuplicate = document.querySelector(
          '.product-svg #Zones #' + zone + 'Duplicate'
        )
        if (zoneCustomlogoDuplicate) {
          let imageDuplicate = document.querySelector(
            '.product-svg svg defs #pattern-' + zone + '_duplicate #patimg-' + zone + '_duplicate'
          )
          let xlinkImageDuplicate = imageDuplicate.getAttributeNS(
            'http://www.w3.org/1999/xlink',
            'href'
          )
          if (xlinkImageDuplicate !== imgbase64Str) {
            imageDuplicate.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgbase64Str)
          }
          zoneCustomlogoDuplicate.querySelector('.use-logo').style.fill =
            'url(#pattern-' + zone + ')'
        }

        if (customLogos[zone] && customLogos[zone].file) {
          if (
            customLogos[zone].file.filename !== file.filename &&
            customLogos[zone].file.fileSize !== file.fileSize
          ) {
            customLogos[zone] = { file: file, saved: false }
            setTimeout(
              function() {
                this.setState({ customLogos: customLogos })
              }.bind(this),
              500
            )
          }
        } else {
          customLogos[zone] = { file: file, saved: false }
          setTimeout(
            function() {
              this.setState({ customLogos: customLogos })
            }.bind(this),
            500
          )
        }
      } else if (action === 're-add') {
        let imgbase64 = file.getFileEncodeBase64String()
        let imgbase64Str = 'data:image/png; base64,' + imgbase64
        let image = document.querySelector(
          '.product-svg svg defs #pattern-' + zone + ' #patimg-' + zone
        )
        let xlinkImage = image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
        if (xlinkImage !== imgbase64Str) {
          image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgbase64Str)
        }
        let zoneCustomlogo = document.querySelector('.product-svg #Zones #' + zone)
        zoneCustomlogo.querySelector('.use-logo').style.fill = 'url(#pattern-' + zone + ')'

        let zoneCustomlogoDuplicate = document.querySelector(
          '.product-svg #Zones #' + zone + 'Duplicate'
        )
        if (zoneCustomlogoDuplicate) {
          let imageDuplicate = document.querySelector(
            '.product-svg svg defs #pattern-' + zone + '_duplicate #patimg-' + zone + '_duplicate'
          )
          let xlinkImageDuplicate = imageDuplicate.getAttributeNS(
            'http://www.w3.org/1999/xlink',
            'href'
          )
          if (xlinkImageDuplicate !== imgbase64Str) {
            imageDuplicate.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imgbase64Str)
          }
          zoneCustomlogoDuplicate.querySelector('.use-logo').style.fill =
            'url(#pattern-' + zone + ')'
        }

        customLogos[zone] = { file: file, saved: true }
        setTimeout(
          function() {
            this.setState({ customLogos: customLogos })
          }.bind(this),
          500
        )
      } else if (action === 'remove') {
        let image = document.querySelector(
          '.product-svg svg defs #pattern-' + zone + ' #patimg-' + zone
        )
        if (image) {
          image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#')
          let zoneCustomlogo = document.querySelector('.product-svg #Zones #' + zone)
          zoneCustomlogo.querySelector('.use-logo').style.fill = 'none'

          let zoneCustomlogoDuplicate = document.querySelector(
            '.product-svg #Zones #' + zone + 'Duplicate'
          )
          if (zoneCustomlogoDuplicate) {
            let imageDuplicate = document.querySelector(
              '.product-svg svg defs #pattern-' + zone + '_duplicate #patimg-' + zone + '_duplicate'
            )
            if (imageDuplicate) {
              imageDuplicate.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#')
            }
            zoneCustomlogoDuplicate.querySelector('.use-logo').style.fill = 'none'
          }

          customLogos[zone] = { file: null, saved: false }
          this.setState({ customLogos: customLogos })
        }
      }
    } else if (typeFile === 'ai') {
      if (action === 'add') {
        if (customAiFilesLogos[zone] && customAiFilesLogos[zone].file) {
          if (
            customAiFilesLogos[zone].file.filename !== file.filename &&
            customAiFilesLogos[zone].file.fileSize !== file.fileSize
          ) {
            customAiFilesLogos[zone] = { file: file, saved: false }
            setTimeout(
              function() {
                this.setState({ customAiFilesLogos: customAiFilesLogos })
              }.bind(this),
              500
            )
          }
        } else {
          customAiFilesLogos[zone] = { file: file, saved: false }
          setTimeout(
            function() {
              this.setState({ customAiFilesLogos: customAiFilesLogos })
            }.bind(this),
            500
          )
        }
      } else if (action === 're-add') {
        customAiFilesLogos[zone] = { file: file, saved: true }
        setTimeout(
          function() {
            this.setState({ customAiFilesLogos: customAiFilesLogos })
          }.bind(this),
          500
        )
      } else if (action === 'remove') {
        customAiFilesLogos[zone] = { file: null, saved: false }
        this.setState({ customAiFilesLogos: customAiFilesLogos })
      }
    }
  }

  getMenuHeading() {
    if (!this.state.selectedZone) {
      return 'Select a Zone'
    }

    let heading = this.state.selectedZone.name
    if (this.state.selectedColor) {
      heading += ': ' + this.state.selectedColor.name
    }

    return heading
  }

  // transformSpecNameLowercase(spec) {
  //   return spec.name.toLowerCase();
  // }

  render() {
    const {
      product,
      products,
      classes,
      formErrors,
      formValues,
      match: {
        params: { productId }
      },
      isMatchingProduct,
      isProductImagesSaving,
      rules,
      specOptions,
      valid,
      instance,
      zoneOptions,
      productOptions
    } = this.props
    const {
      selectedColor,
      selectedZone,
      hoverZone,
      customLogos,
      customAiFilesLogos,
      selectedProductOption,
      productOptionMessage
    } = this.state
    const zones = get(product, 'zones.data', [])
    const formErrorText = getFormErrorText(formErrors)
    const embroideryValue = specOptions.length
      ? getEmbroideryValue(specOptions, formValues)
      : undefined
    const globalError = this.state.globalError
    const error = validateRules(
      selectedZone ? selectedZone.id : undefined,
      selectedColor,
      zones,
      rules,
      embroideryValue
    )
    const disabled = Boolean(globalError || error || formErrorText)
    const embroideryColor = zoneOptions.filter(
      v => v.name.toUpperCase() === 'EMBROIDERY' && v.is_active == true
    )
    const variation = get(instance, 'attributes.customizer.data.variation')

    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductPage:: render() this.state', this.state)
      console.log('ProductPage:: render() this.props', this.props)
      console.log('ProductPage:: render() product', product)
      console.log('ProductPage:: render() zones', zones)
      console.log('ProductPage:: render() selectedProductOption', selectedProductOption)
      console.log('ProductPage:: render() embroideryColor', embroideryColor)
      console.log('ProductPage:: render() embroideryValue', embroideryValue)
      console.log('ProductPage:: render() zoneOptions', zoneOptions)
      console.log('ProductPage:: render() specOptions', specOptions)
    }

    return (
      <div className={classes.root}>
        {variation === 'team' && (
          <ProductHeader
            customLogos={customLogos}
            customAiFilesLogos={customAiFilesLogos}
            product={product}
            step="intro"
            disabled={disabled}
            saveLastZone={this.saveLastZone}
            variation={variation}
          />
        )}
        {variation !== 'team' && (
          <ProductHeader
            product={product}
            step="intro"
            disabled={disabled}
            saveLastZone={this.saveLastZone}
            variation={variation}
          />
        )}

        <Main>
          {zoneOptions.length > 0 && (
            <SidebarMenu domRef={this.handleContentRef}>
              <SelectZone
                selectZone={this.handleSelectZone}
                hoverZone={this.handleHoverZone}
                unhoverZone={this.handleUnhoverZone}
                clearZone={this.handleClearZone}
                selectColor={this.handleSelectColor}
                postHeightMessage={this.postHeightMessage.bind(this)}
                color={selectedColor}
                zone={selectedZone}
                product={product}
                disabled={disabled}
                rules={rules}
                isFormValid={valid}
                selectCustomLogo={this.handleCustomLogo}
                submitCustomLogo={this.handleSubmitCustomLogo}
                deleteCustomLogo={this.handleDeleteCustomLogo}
                customLogos={customLogos}
                customAiFilesLogos={customAiFilesLogos}
                variation={variation}
              />
            </SidebarMenu>
          )}
          <Content className={classes.content}>
            <Grid container spacing={0} alignItems="center" className={classes.container}>
              <Grid item className={classes.ref}>
                <Typography className={classes.bold}>REF#</Typography>
                <Typography>{instance && instance.id}</Typography>
              </Grid>
              {specOptions && (
                <Grid item>
                  <div className={classes.specs}>
                    {specOptions.map(spec => {
                      const validate = spec.required
                        ? [validateEmbRules, validateRequired]
                        : [validateEmbRules]
                      return (
                        <SpecField
                          name={'spec_' + spec.id}
                          key={spec.id}
                          label={spec.name}
                          spec={spec}
                          validate={validate}
                          onChange={this.handleSpecChange}
                        />
                      )
                    })}
                    {specOptions.length > 0 && (
                      <div
                        className={classes.colorSwatch}
                        style={
                          embroideryColor.length > 0 && embroideryColor[0].color
                            ? {
                                backgroundColor: embroideryColor[0].color.hex,
                                backgroundImage: 'none'
                              }
                            : null
                        }
                      />
                    )}
                  </div>
                </Grid>
              )}
              {products.length > 1 ? (
                <Grid item>
                  <MatchProduct product={product} />
                </Grid>
              ) : null}
            </Grid>
            {(globalError || this.state.error || error || formErrorText) && (
              <div elavation={0} className={cx(classes.message, { [classes.errorMessage]: true })}>
                <Typography color="error" variant="body2" align="center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.error ||
                        globalError ||
                        (error && error.type ? error.message : error) ||
                        formErrorText
                    }}
                  />
                </Typography>
              </div>
            )}
            {productOptionMessage && (
              <div elavation={0} className={cx(classes.message, { [classes.errorMessage]: true })}>
                <Typography color="error" variant="body2" align="center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productOptionMessage
                    }}
                  />
                </Typography>
              </div>
            )}
            <ProductDetail
              key={productId}
              product={product}
              zone={selectedZone}
              hoverZone={hoverZone}
              color={selectedColor}
              selectZone={this.handleSelectZone}
              postHeightMessage={this.postHeightMessage.bind(this)}
            />
          </Content>
          {productOptions && selectedProductOption && (
            <SelectProductOption
              key={productId}
              product={product}
              productOptions={productOptions}
              selectProductOption={this.handleSelectProductOption}
              pOption={selectedProductOption}
            />
          )}
          {(isMatchingProduct || isProductImagesSaving) && <LoadingIndicator />}
        </Main>
      </div>
    )
  }
}

const actions = {
  updateCustomizerProductSpecs,
  updateInstanceZoneColor,
  updateInstanceZoneLogo,
  deleteInstanceZoneLogo,
  updateInstanceProductOption,
  updateProductImages
}

const selector = createStructuredSelector({
  formErrors: getFormSyncErrors('specsForm'),
  formMeta: getFormMeta('specsForm'),
  formValues: getFormValues('specsForm'),
  instance: customizerInstanceSelector,
  isMatchingProduct: isMatchingProductSelector,
  isProductImagesSaving: isProductImagesSavingSelector,
  products: productsSelector,
  initialValues: compose(getSpecsValues, customizerInstanceSelector),
  product: (state, props) =>
    fp.compose(
      products => find(products, { id: parseInt(props.match.params.productId, 10) }),
      productsSelector
    )(state),
  rules: rulesSelector,
  specOptions: (state, props) =>
    customizerSpecOptionsSelector(props.match.params.productId, 'product-zones')(state),
  zoneOptions: (state, props) => zoneOptionsSelector(props.match.params.productId)(state),
  productOptions: (state, props) =>
    customizerProductOptionsSelector(props.match.params.productId)(state)
})

export default compose(
  connect(selector, actions),
  reduxForm({
    form: 'specsForm',
    destoryOnUnmount: false,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true
  }),
  withStyles(styles)
)(ProductPage)
