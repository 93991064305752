export default theme => ({
  root: {
    overflow: 'hidden'
  },
  content: {
    height: '100vh',
    display: 'flex',
    overflow: 'auto',
    padding: theme.spacing.unit * 3,
    flexDirection: 'column'
  }
})
