import React, { Component } from 'react'
import get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { saveAs } from 'file-saver'

class ProductColorsTab extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    colorsApproved: PropTypes.bool.isRequired,
    setColorsApproved: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    variation: PropTypes.string
  }

  handleColorApprovedChange = event => {
    this.props.setColorsApproved(event.target.checked)
  }

  handleDownloadPNGFile = () => {
    const { product } = this.props
    const url = product.image.png
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductColorsTab handleDownloadFile product.image.png', product.image.png)
    }
    const resURL = url.split('/')
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductColorsTab handleDownloadFile resPNG', resURL)
    }
    const nameFile = resURL.slice(-1)[0]
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductColorsTab handleDownloadFile namePNG', nameFile)
    }

    saveAs(url, nameFile)
  }

  render() {
    const { product, classes, colorsApproved, variation, hasError } = this.props
    const zones = get(product, 'zones.data')
    var namePNG = ''
    var nameSVG = ''

    const downloadAvailable = false

    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductColorsTab RENDER product', product)
    }

    if (product.image.png) {
      const resPNG = product.image.png.split('/')
      if (process.env.REACT_APP_APP_DEBUG) {
        console.log('ProductColorsTab RENDER resPNG', resPNG)
      }
      namePNG = resPNG.slice(-1)[0]
      if (process.env.REACT_APP_APP_DEBUG) {
        console.log('ProductColorsTab RENDER namePNG', namePNG)
      }
    }

    if (product.image.svg) {
      const resSVG = product.image.svg.split('/')
      if (process.env.REACT_APP_APP_DEBUG) {
        console.log('ProductColorsTab RENDER resSVG', resSVG)
      }
      nameSVG = resSVG.slice(-1)[0]
      if (process.env.REACT_APP_APP_DEBUG) {
        console.log('ProductColorsTab RENDER nameSVG', nameSVG)
      }
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={40}>
          <Grid xs item>
            {zones.map(zone => {
              var style = zone.color
                ? { backgroundColor: zone.color.hex }
                : { border: '1px solid #AAA' }
              if (zone.color && zone.color.hex === '#ffffff') {
                style = { ...style, border: '1px solid lightgrey' }
              }
              if (zone.gui_visible) {
                if (!zone.is_logo) {
                  if ((zone.is_option && zone.is_active) || !zone.is_option) {
                    return (
                      <Grid container spacing={16} key={zone.id} alignItems="center">
                        <Grid item>
                          <Typography>
                            <b>{zone.name}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <hr className={classes.hr} />
                        </Grid>
                        <Grid item>
                          <div className={classes.zoneColor} style={style} />
                        </Grid>
                        <Grid item xs={2} className={classes.colorName}>
                          <Typography>{zone.color ? zone.color.name : 'N/A'}</Typography>
                        </Grid>
                      </Grid>
                    )
                  }
                } else if (zone.is_logo && variation === 'team') {
                  return (
                    <Grid container spacing={16} key={zone.id} alignItems="center">
                      <Grid item>
                        <Typography>
                          <b>{zone.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <hr className={classes.hr} />
                      </Grid>
                      <Grid item xs={3} className={classes.zoneLogo}>
                        {zone.logo && zone.logo.png_url && zone.logo.png_url !== '' && (
                          <img className={classes.logo} src={zone.logo.png_url} alt={zone.name} />
                        )}

                        {!zone.logo && <Typography>No logo</Typography>}
                        {zone.logo && !zone.logo.png_url && <Typography>No logo</Typography>}
                        {zone.logo && zone.logo.png_url && zone.logo.png_url === '' && (
                          <Typography>No logo</Typography>
                        )}
                      </Grid>
                    </Grid>
                  )
                } else {
                  return null
                }
              } else {
                return null
              }
            })}
            {variation === 'retail' && (
              <Toolbar className={classes.toolbar}>
                <Checkbox
                  color="default"
                  checked={colorsApproved}
                  onChange={this.handleColorApprovedChange}
                />
                <Typography color={hasError ? 'error' : 'inherit'}>
                  I confirm that materials and colors selected have been reviewed before proceeding
                </Typography>
              </Toolbar>
            )}
          </Grid>
          {product && (
            <Grid xs={7} item className={classes.imageBox}>
              <img
                className={classes.image}
                src={product.image.svg || product.image.png}
                alt={product.name}
              />
              {product.image && product.image.png && downloadAvailable && (
                <Button
                  className={classes.imageBtnDdl}
                  variant="outlined"
                  onClick={this.handleDownloadPNGFile}
                >
                  {' '}
                  PNG DOWNLOAD{' '}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ProductColorsTab)
