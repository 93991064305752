export default theme => ({
  root: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    padding: '0.5rem 1.5rem'
  },
  logoNotice: {
    textTransform: 'uppercase',
    lineHeight: '1.3em'
  },
  btnSaveLogo: {
    backgroundColor: 'rgb(64, 150, 53)',
  },
  btnDeleteLogo: {
    backgroundColor: 'rgb(234, 35, 48)',
  },
})
