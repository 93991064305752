import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import SelectAgeGroup from 'components/SelectAgeGroup'
import SelectLocation from 'components/SelectLocation'
import { actions as locationActions, selectors } from 'redux/modules/location'
import { productLineSelector } from 'redux/modules/productLine/selectors'
import styles from '../styles'

class Welcome extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    getLocations: PropTypes.func.isRequired,
    isLocationsLoaded: PropTypes.bool,
    isLocationsLoading: PropTypes.bool,
    locations: PropTypes.array,
    queryParams: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { productLine } = this.props
    this.props.getLocations({
      params: {
        variation: productLine.variation,
        line: productLine.line
      }
    })
  }

  renderSpinner() {
    return <div />
  }

  render() {
    const { classes, ageGroups, locations, isLocationsLoaded, queryParams } = this.props

    return (
      <div>
        {isLocationsLoaded ? (
          <div>
            {!queryParams.ccm_customizer_location && (
              <Fragment>
                <Typography className={classes.heading} variant="title">
                  Choose a Location
                </Typography>
                <SelectLocation locations={locations} />
              </Fragment>
            )}
            {!queryParams.ccm_customizer_age_group && (
              <Fragment>
                <Typography className={classes.heading} variant="title">
                  Choose an Age Group
                </Typography>
                <SelectAgeGroup ageGroups={ageGroups} />
              </Fragment>
            )}
          </div>
        ) : (
          this.renderSpinner()
        )}
      </div>
    )
  }
}

const selector = createStructuredSelector({
  ageGroups: selectors.ageGroupsSelector,
  locations: selectors.locationsSelector,
  isLocationsLoaded: selectors.isLocationsLoadedSelector,
  isLocationsLoading: selectors.isLocationsLoadingSelector,
  productLine: productLineSelector
})

const actions = {
  ...locationActions
}

export default compose(
  withStyles(styles),
  connect(
    selector,
    actions
  )
)(Welcome)
