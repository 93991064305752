import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { getUser } from 'redux/modules/customizer/actions'
import { parseQueryString } from 'utils/helpers'
import {
  selectAgeGroup,
  selectLine,
  selectLocation,
  selectVariation,
  selectVersion
} from 'redux/modules/productLine/actions'

export default WrappedComponent => {
  class QueryParamsHandler extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired
    }

    componentDidMount() {
      const {
        getUser,
        history,
        location,
        selectAgeGroup,
        selectLine,
        selectLocation,
        selectVariation,
        selectVersion
      } = this.props
      const queryParams = parseQueryString(location.search)

      if (queryParams.ccm_customizer_age_group) {
        selectAgeGroup(queryParams.ccm_customizer_age_group)
      }
      if (queryParams.ccm_customizer_line) {
        selectLine(queryParams.ccm_customizer_line)
      }
      if (queryParams.ccm_customizer_location) {
        selectLocation(queryParams.ccm_customizer_location)
      }
      if (queryParams.ccm_customizer_variation) {
        selectVariation(queryParams.ccm_customizer_variation)
      }
      if (queryParams.ccm_customizer_version) {
        selectVersion(queryParams.ccm_customizer_version)
      }

      const userEmail = queryParams.ccm_customizer_user_email || sessionStorage.userEmail
      if (userEmail) {
        sessionStorage.userEmail = userEmail
        getUser({ params: { email: userEmail } })
      }

      if (queryParams.ccm_customizer_instance) {
        history.replace(`/instance/${queryParams.ccm_customizer_instance}`)
      }
    }

    render() {
      const { location } = this.props
      return <WrappedComponent {...this.props} queryParams={parseQueryString(location.search)} />
    }
  }

  const actions = {
    getUser,
    selectAgeGroup,
    selectLine,
    selectLocation,
    selectVariation,
    selectVersion
  }

  return compose(
    withRouter,
    connect(
      null,
      actions
    )
  )(QueryParamsHandler)
}
