import fp from 'lodash/fp'
import { selectors } from '../api'

export const locationStateSelector = fp.get('location')

export const isLocationsLoadingSelector = selectors.isRequestPending('locations', 'get')

export const isLocationsLoadedSelector = selectors.isRequestSuccess('locations', 'get')

export const locationsSelector = fp.get('location.locations')

export const ageGroupsSelector = fp.get('location.ageGroups')

export const storesListSelector = selectors.dataSelector('storesList', [])
