import { createAction } from 'redux-actions'

import * as types from './types'

export const getProductLine = createAction(types.GET_PRODUCT_LINE)

export const selectProduct = createAction(types.PRODUCT_LINE_SELECT_PRODUCT)

export const selectAgeGroup = createAction(types.PRODUCT_LINE_SELECT_AGE_GROUP)

export const selectLine = createAction(types.PRODUCT_LINE_SELECT_LINE)

export const selectLocation = createAction(types.PRODUCT_LINE_SELECT_LOCATION)

export const selectVariation = createAction(types.PRODUCT_LINE_SELECT_VARIATION)

export const selectVersion = createAction(types.PRODUCT_LINE_SELECT_VERSION)
