export default theme => ({
  root: {
    padding: '3rem',
    border: '1px solid lightgray'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  },
  noPaddingBottom: {
    paddingBottom: '0 !important'
  },
  noPaddingTop: {
    paddingTop: '5px !important'
  }
})
