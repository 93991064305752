export default theme => ({
  root: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    padding: '0.5rem 1.5rem'
  },
  groupTitle: {
    textTransform: 'uppercase'
  },
  group: {
    marginBottom: '1rem'
  },
  groupLittleColor: {
    marginBottom: '2.5rem'
  },
  color: {
    boxShadow: '0 0 4px 1px rgba(0,0,0,0.20)',
    display: 'inline-block',
    width: 20,
    height: 20,
    backgroundColor: 'white',
    border: '2px solid white',
    borderRadius: '50%',
    textIndent: '-999em',
    cursor: 'pointer',
    margin: '0 8px 8px 0'
  },
  question: {
    marginLeft: '0.5rem',
    cursor: 'pointer'
  },
  preview: {
    position: 'fixed',
    zIndex: '9',
    border: '3px solid white',
    backgroundColor: 'white',
    boxShadow: '1px 2px 7px 0px rgba(0,0,0,0.2)',
    width: '300px'
  },
  previewImage: {
    width: '100%'
  },
  colorSelected: {
    boxShadow: '0 0 0px 4px rgba(0,0,0,0.20)',
    width: 20,
    height: 20
  },
  helpButton: {
    width: 24,
    height: 24,
    margin: '0 0 5px 10px',
    textAlign: 'center',
    color: 'lightgray'
  }
})
