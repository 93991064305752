import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class CustomizerTitlebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object
  }

  render() {
    const { classes, product } = this.props
    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.flex}>
            <b>{product && product.name}</b>
          </Typography>
        </Toolbar>
      </div>
    )
  }
}

export default compose(withStyles(styles))(CustomizerTitlebar)
