import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import fp from 'lodash/fp'
import find from 'lodash/find'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import cx from 'classnames'

import LoadingIndicator from 'components/LoadingIndicator'
import styles from './styles'
import { propsChanged } from 'utils/helpers'
import { zoneOptionsSelector } from 'redux/modules/customizer/selectors'

class ProductDetail extends Component {
  static propTypes = {
    product: PropTypes.object,
    selectZone: PropTypes.func.isRequired,
    zone: PropTypes.object,
    hoverZone: PropTypes.object,
    color: PropTypes.object,
    classes: PropTypes.object.isRequired
  }

  componentDidMount() {
    this._mounted = true
    //this.props.selectZone(null)
  }

  componentDidUpdate(prevProps) {
    const { zone, color, hoverZone } = this.props
    if (hoverZone) {
      this.setSvgZone(hoverZone, true)
    } else if (zone) {
      this.setSvgZone(zone, false)
    }
    if (propsChanged(['product.zones.data'], this.props, prevProps)) {
      this.loadZoneColors()
    }

    if (color && zone) {
      this.setZoneColor(color, zone)
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  addSvgListeners = () => {
    document.querySelectorAll('.product-svg #Zones g[id]').forEach(el => {
      if (this._mounted) {
        this.loadZoneColors()
      }
      el.addEventListener('click', event => {
        const zoneGroup = event.target.parentNode
        this.selectGroupZone(zoneGroup.id)
      })
    })
  }

  loadZoneColors() {
    const { product } = this.props
    get(product, 'zones.data', []).forEach(zone => {
      if (zone.color) {
        this.setZoneColor(zone.color, zone)
      }
    })
  }

  selectGroupZone(zoneId) {
    const { selectZone, options } = this.props
    let zone = find(options, { slug: zoneId })
    selectZone(zone)
  }

  setSvgZone(zone, hover) {
    // Remove selections
    document.querySelectorAll('.product-svg #Zones g').forEach(el => {
      el.removeAttribute('class')
    })

    // Add to selected group
    const zoneGroup = document.querySelector('.product-svg #Zones #' + zone.slug)
    if (zoneGroup) {
      zoneGroup.setAttribute('class', hover ? 'is-hover' : 'is-focused')
    }
  }

  setZoneColor(color, zone) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductDetail setZoneColor zone', zone)
    }

    if (zone.is_child) {
      return
    }
    // Add to selected group
    const zoneGroup = document.querySelector('.product-svg #Zones #' + zone.slug)
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductDetail setZoneColor zoneGroup', zoneGroup)
    }
    if (zoneGroup) {
      if (zone.is_gradient) {
        if (document.querySelector('.product-svg svg defs #lg_' + zone.slug + ' .gradient-color')) {
          document.querySelector(
            '.product-svg svg defs #lg_' + zone.slug + ' .gradient-color'
          ).style.stopColor = color.hex
        }
      } else {
        zoneGroup.querySelector('.use-color').style.fill = color.hex
        if (get(color, 'group') === 'emboss' || get(color, 'group.name') === 'emboss') {
          zoneGroup.querySelector('.use-emboss').style.display = null
        } else {
          zoneGroup.querySelector('.use-emboss').style.display = 'none'
        }
      }

      // Check if zone has child if yes assign color to the child
      if (zone.has_child && zone.children && zone.children.data) {
        if (process.env.REACT_APP_APP_DEBUG) {
          console.log('ProductDetail setZoneColor zone.children', zone.children)
        }
        zone.children.data.forEach(child => {
          if (process.env.REACT_APP_APP_DEBUG) {
            console.log('ProductDetail setZoneColor child', child)
          }
          const childGroup = document.querySelector('.product-svg #Zones #' + child.slug)
          const colorChild = fp.find({ parent_color_id: color.id })(child.matches.data)
          let childColorHex = ''
          if (process.env.REACT_APP_APP_DEBUG) {
            console.log('ProductDetail setZoneColor childGroup', childGroup)
            console.log('ProductDetail setZoneColor colorChild', colorChild)
          }
          if (colorChild && colorChild.child_color) {
            childColorHex = colorChild.child_color.hex
          } else {
            childColorHex = color.hex
          }

          if (process.env.REACT_APP_APP_DEBUG) {
            console.log('ProductDetail setZoneColor childColorHex', childColorHex)
          }

          if (child.is_gradient) {
            if (
              document.querySelector('.product-svg svg defs #lg_' + child.slug + ' .gradient-color')
            ) {
              document.querySelector(
                '.product-svg svg defs #lg_' + child.slug + ' .gradient-color'
              ).style.stopColor = childColorHex
            }
          } else {
            childGroup.querySelector('.use-color').style.fill = childColorHex
            if (get(color, 'group') === 'emboss' || get(color, 'group.name') === 'emboss') {
              childGroup.querySelector('.use-emboss').style.display = null
            } else {
              childGroup.querySelector('.use-emboss').style.display = 'none'
            }
          }
        })
      }
    }
  }

  render() {
    const { classes, product, options } = this.props

    return (
      <div className={cx(classes.root, { [classes.noZone]: options.length === 0 })}>
        {product && (
          <SVG
            className="product-svg"
            src={product.image.svg}
            uniquifyIDs={false}
            cacheGetRequests={false}
            preloader={<LoadingIndicator />}
            onLoad={this.addSvgListeners}
          >
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${product.image.svg || product.image.png})` }}
            />
          </SVG>
        )}
      </div>
    )
  }
}

const selector = createStructuredSelector({
  options: (state, props) => zoneOptionsSelector(props.match.params.productId)(state)
})

export default compose(withRouter, connect(selector), withStyles(styles))(ProductDetail)
