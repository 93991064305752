export default theme => ({
  flex: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left'
  },
  toolbar: {
    padding: '0'
  },
  helpButton: {
    width: 24,
    height: 24,
    marginRight: theme.spacing.unit,
    textAlign: 'center'
  }
})
