import { handleActions } from 'redux-actions'
import * as types from './types'

export default handleActions(
  {
    [types.SET_COLORS_APPROVED]: (state, { payload }) => ({
      ...state,
      colorsApproved: Boolean(payload)
    })
  },
  {
    instance: null,
    colorsApproved: false
  }
)

const initialState = {
  ajaxCallsInProgress: 0
}

export const ajaxStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AJAX_REQUEST_START':
      return { ...state, ajaxCallsInProgress: state.ajaxCallsInProgress + 1 }
    case 'AJAX_REQUEST_END':
      return { ...state, ajaxCallsInProgress: Math.max(0, state.ajaxCallsInProgress - 1) }
    default:
      return state
  }
}
