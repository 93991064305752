import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import fp from 'lodash/fp'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import ArrowDropDown from '@material-ui/icons/KeyboardArrowDown'

import styles from './styles'
import { customizerInstanceSelector } from 'redux/modules/customizer/selectors'
import { matchProduct } from 'redux/modules/customizer/actions'

const filterProducts = (productId, instance) =>
  fp.compose(
    fp.reject({ id: productId }),
    fp.defaultTo([]),
    fp.get('attributes.products.data')
  )(instance)

class Breadcrumbs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    matchProduct: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMenuItemClick = matchProductId => () => {
    const { instance, matchProduct, product } = this.props
    if( process.env.REACT_APP_APP_DEBUG ){console.log('matchProduct handleMenuItemClick')}
    this.handleClose()
    matchProduct({
      instanceId: instance.id,
      productId: product.id,
      matchProductId
    })
  }

  render() {
    const { classes, instance, product } = this.props
    const { anchorEl } = this.state
    const products = filterProducts(product ? product.id : null, instance)

    return (
      <div className={classes.root}>
        <Button
          onClick={this.handleButtonClick}
          className={cx(classes.dropdown)}
          classes={{ label: classes.disableUppercase }}
        >
          Match colors from
          <ArrowDropDown />
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {products.map(product => (
            <MenuItem key={product.id} onClick={this.handleMenuItemClick(product.id)}>
              {product.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  instance: customizerInstanceSelector
})

const actions = {
  matchProduct
}

export default compose(
  connect(
    selector,
    actions
  ),
  withStyles(styles)
)(Breadcrumbs)
