import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'
import Typography from '@material-ui/core/Typography'

class SidebarMenuHeading extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  }

  render() {
    const { classes, children } = this.props
    return (
      <div className={classes.selectedColor}>
        <FontAwesomeIcon icon={faTint} />
        <Typography className={cx(classes.bold, classes.minorFont)}>Selected Color: </Typography>
        <Typography className={classes.minorFont}> {children} </Typography>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(SidebarMenuHeading)
