import React, { Component } from 'react'
import Button from 'components/GradientButton'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from '../styles'

import SelectProduct from 'components/SelectProduct'
import { actions as customizerActions } from 'redux/modules/customizer'
import { actions as productLineActions, selectors } from 'redux/modules/productLine'

class ProductSelector extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    searchCustomizers: PropTypes.func.isRequired,
    getProductLine: PropTypes.func.isRequired,
    isProductLineLoaded: PropTypes.bool,
    createCustomizerInstance: PropTypes.func.isRequired,
    productLine: PropTypes.object,
    queryParams: PropTypes.object
  }

  componentDidMount() {
    const { productLineState, queryParams } = this.props
    this.props.getProductLine({
      params: {
        customizer: get(queryParams, 'ccm_customizer_id') || productLineState.customizer,
        variation: productLineState.variation
      }
    })
  }

  handleCustomize = () => {
    const { productLineState, searchCustomizers, queryParams } = this.props
    const customizerId = get(queryParams, 'ccm_customizer_id')
    console.log('ProductSelector handleCustomize  --  productLineState', productLineState)
    searchCustomizers({
      params: {
        customizer_id: customizerId,
        age_group: productLineState.ageGroup,
        line: productLineState.line,
        location: productLineState.location,
        variation: productLineState.variation,
        version: productLineState.version
      },
      success: customizers => {
        customizers.length > 0 && this.handleNewCustomizerInstance(customizers[0])
      }
    })
  }

  handleNewCustomizerInstance = customizer => {
    const { history, createCustomizerInstance, productLineState } = this.props
    createCustomizerInstance({
      id: customizer.id,
      params: {
        products: productLineState.selectedProducts.join(',') // comma delimited string of product IDs.
      },
      success: instance => {
        history.push(`instance/${instance.id}/product/${productLineState.selectedProducts[0]}`)
      }
    })
  }

  renderLoader() {
    return <div className={this.props.classes.mainBody}>Loading products...</div>
  }

  render() {
    const { classes, children, productLine, isProductLineLoaded } = this.props

    return (
      <div className={classes.main}>
        {isProductLineLoaded ? (
          <div>
            <Typography className={classes.mainHeader} variant="headline">
              {get(productLine, 'attributes.customizer_name')}
            </Typography>

            <div className={classes.mainBody}>
              {children}

              <Typography variant="headline">
                <b>Select your products</b>
              </Typography>

              <SelectProduct products={get(productLine, 'attributes.products.data')} />
              <Button
                variant="raised"
                color="primary"
                onClick={this.handleCustomize}
                className={classes.customButton}
              >
                Customize &nbsp;&#9654;
              </Button>
            </div>
          </div>
        ) : (
          this.renderLoader()
        )}
      </div>
    )
  }
}

const selector = createStructuredSelector({
  productLine: selectors.productLineSelector,
  productLineState: selectors.productLineStateSelector,
  isProductLineLoaded: selectors.isProductLineLoadedSelector,
  isProductLineLoading: selectors.isProductLineLoadingSelector
})

const actions = {
  ...customizerActions,
  ...productLineActions
}

export default compose(withRouter, withStyles(styles), connect(selector, actions))(ProductSelector)
