import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import IconHelpOutline from '@material-ui/icons/HelpOutline'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class SubmitOrderTitlebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.flex}>
            Submit Order
          </Typography>
          <Tooltip id="help-tooltip" title="TODO: Add your tooltip text here.">
            <IconButton className={classes.helpButton}>
              <IconHelpOutline />
            </IconButton>
          </Tooltip>
          <Typography>
            Fill in your information below and select a store to submit your order.
          </Typography>
        </Toolbar>
      </div>
    )
  }
}

export default compose(withStyles(styles))(SubmitOrderTitlebar)
