import React from 'react'
import xor from 'lodash/xor'
import { connect } from 'react-redux'
import { selectProduct } from 'redux/modules/productLine/actions'
import { Typography, Checkbox } from '@material-ui/core'

class SelectProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: []
    }
  }

  handleChange = id => () => {
    const { selectProduct, products } = this.props
    const newSelection = xor(this.state.selected, [id])
    const selected = products
      .filter(product => newSelection.includes(product.id))
      .map(product => product.id)
    selectProduct(selected)
    this.setState({ selected })
  }

  render() {
    return (
      <ul className="select-options">
        {this.props.products.map((product, index) => (
          <li
            key={index}
            className={'select-options__item'}
            onClick={this.handleChange(product.id)}
          >
            <img src={product.image.svg} alt={product.id} width="250" />
            <Typography>{product.name}</Typography>
            <Checkbox
              checked={this.state.selected.includes(product.id)}
              onChange={this.handleChange(product.id)}
            />
          </li>
        ))}
      </ul>
    )
  }
}

const actions = {
  selectProduct
}

SelectProduct = connect(
  null,
  actions
)(SelectProduct)

export default SelectProduct
