import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class SidebarMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    domRef: PropTypes.func
  }

  render() {
    const { classes, children, domRef } = this.props

    return (
      <nav className={classes.root} ref={domRef}>
        {children}
      </nav>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(SidebarMenu)
