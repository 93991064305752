import React, { Component } from 'react'
import cx from 'classnames'
import fp from 'lodash/fp'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import {
  colorOptionsSelector,
  customizerProductsSelector,
  zoneColorOptionsSelector
} from 'redux/modules/customizer/selectors'

class SelectColor extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    colorOptions: PropTypes.array,
    rules: PropTypes.array,
    zone: PropTypes.object.isRequired,
    variation: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      colorGroup: null
    }
  }

  getColors(colorIds) {
    const { colors } = this.props
    return colorIds.map(colorId => fp.find({ id: colorId })(colors)).filter(color => color)
  }

  handleSelectColor = color => () => {
    this.props.selectColor(color)
  }

  handleQuestionOver = group => () => this.setState({ colorGroup: group })

  handleQuestionOut = () => this.setState({ colorGroup: null })

  groupPreviewUrl = () =>
    this.state.colorGroup === 'solid'
      ? process.env.PUBLIC_URL + '/solid_opt.jpg'
      : this.state.colorGroup === 'emboss'
        ? process.env.PUBLIC_URL + '/emboss_opt.jpg'
        : process.env.PUBLIC_URL + '/speedskin_opt.jpg'

  render() {
    const { classes, color: selectedColor, colorOptions, variation } = this.props
    return (
      <div className={classes.root}>
        {colorOptions &&
          colorOptions.map(item => (
            <div
              key={item.group_id}
              className={cx(classes.group, { [classes.groupLittleColor]: item.colors.length <= 8 })}
            >
            {variation !== 'team' && (
              <Typography variant="body2" gutterBottom className={classes.groupTitle}>
                {item.group_name}
              </Typography>
            )}

              {item.colors.length &&
                this.getColors(item.colors).map(color => (
                  <Tooltip
                    id={'color-tooltip-' + color.name}
                    title={
                      variation !== 'team' || !color.pms ?  color.name :
                      color.ccm_internal_id ?
                      color.name+ ' (' + color.ccm_internal_id+ ') ' +color.pms+ 'C' :
                      color.name+ ' ' +color.pms+ 'C'
                    }
                    key={color.id}
                    placement="top"
                  >
                    <div
                      className={cx(classes.color, {
                        [classes.colorSelected]: selectedColor
                          ? selectedColor.id === color.id
                          : false
                      })}
                      style={{ backgroundColor: color.hex }}
                      onClick={this.handleSelectColor(color)}
                    >
                      {color.name}
                    </div>
                  </Tooltip>
                ))}
            </div>
          ))}
      </div>
    )
  }
}

const selector = createStructuredSelector({
  colors: colorOptionsSelector,
  customizerProduct: (state, props) =>
    compose(
      fp.find({ id: Number(props.product.id) }),
      customizerProductsSelector
    )(state),
  colorOptions: (state, props) => zoneColorOptionsSelector(props.product.id, props.zone.id)(state)
})

export default compose(
  withRouter,
  connect(selector),
  withStyles(styles)
)(SelectColor)
