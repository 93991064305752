export default theme => ({
  flex: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left'
  },
  toolbar: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing.unit * 2,
    borderRadius: 5
  },
  iconCopy: {
    width: 24,
    height: 24,
    marginRight: theme.spacing.unit,
    textAlign: 'center'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 150
  },
  buttonIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing.unit
  },
  button: {
    marginLeft: theme.spacing.unit
  },
  popoverText: {
    padding: theme.spacing.unit * 2
  }
})
