export default theme => ({
  root: {},
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1rem 0'
  },
  inner: {
    width: 'initial'
  },
  bold: {
    fontWeight: 'bolder'
  },
  reviewOrder: {
    '&:not(:last-child):after': {
      content: '"/"',
      padding: `0 ${theme.spacing.unit}px`
    }
  }
})
