import React, { Component } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import ProductsGrid from '../ProductsGrid'
import SaveOrderTitlebar from '../SaveOrderTitlebar'
import styles from './styles'

class SaveOrder extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired
  }

  render() {
    const { instance, classes } = this.props
    return (
      <div id="finalize-save-order" className={classes.root}>
        <SaveOrderTitlebar instance={instance} />
        <ProductsGrid products={get(instance, 'attributes.products.data', [])} />
      </div>
    )
  }
}

export default compose(withStyles(styles))(SaveOrder)
