export const GET_CUSTOMIZER = 'CCM.GET_CUSTOMIZER'
export const SEARCH_CUSTOMIZERS = 'CCM.SEARCH_CUSTOMIZERS'
export const CREATE_CUSTOMIZER_INSTANCE = 'CCM.CREATE_CUSTOMIZER_INSTANCE'
export const GET_CUSTOMIZER_INSTANCE = 'CCM.GET_CUSTOMIZER_INSTANCE'
export const GET_USER = 'CCM.GET_USER'
export const MATCH_PRODUCT = 'CCM.MATCH_PRODUCT'
export const SET_COLORS_APPROVED = 'CCM.SET_COLORS_APPROVED'
export const UPDATE_CUSTOMIZER_PRODUCT = 'CCM.UPDATE_CUSTOMIZER_PRODUCT'
export const UPDATE_CUSTOMIZER_PRODUCT_SPECS = 'CCM.UPDATE_CUSTOMIZER_PRODUCT_SPECS'
export const DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP = 'CCM.DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP'
export const UPDATE_INSTANCE_ZONE_COLOR = 'CCM.UPDATE_INSTANCE_ZONE_COLOR'
export const UPDATE_INSTANCE_ZONE_LOGO = 'CCM.UPDATE_INSTANCE_ZONE_LOGO'
export const DELETE_INSTANCE_ZONE_LOGO = 'CCM.DELETE_INSTANCE_ZONE_LOGO'
export const UPDATE_INSTANCE_PRODUCT_OPTION = 'CCM.UPDATE_INSTANCE_PRODUCT_OPTION'
export const UPDATE_PRODUCT_IMAGES = 'CCM.UPDATE_PRODUCT_IMAGES'
export const SUBMIT_ORDER = 'CCM.SUBMIT_ORDER'
