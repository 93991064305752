import { call, put, takeLatest } from 'redux-saga/effects'
import { ajaxRequestStart, ajaxRequestEnd } from './actions'
import { apiCall } from '../api'
import {
  CREATE_CUSTOMIZER_INSTANCE,
  GET_CUSTOMIZER_INSTANCE,
  GET_USER,
  MATCH_PRODUCT,
  SEARCH_CUSTOMIZERS,
  UPDATE_CUSTOMIZER_PRODUCT,
  UPDATE_CUSTOMIZER_PRODUCT_SPECS,
  DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP,
  UPDATE_INSTANCE_ZONE_COLOR,
  UPDATE_INSTANCE_ZONE_LOGO,
  DELETE_INSTANCE_ZONE_LOGO,
  UPDATE_INSTANCE_PRODUCT_OPTION,
  UPDATE_PRODUCT_IMAGES,
  SUBMIT_ORDER
} from './types'

const searchCustomizers = apiCall({
  type: SEARCH_CUSTOMIZERS,
  method: 'get',
  allowedParamKeys: [
    'customizer_id',
    'location',
    'age_group',
    'variation',
    'line',
    'version',
    'env'
  ],
  path: ({ payload }) => `/api/v1/customizers/search`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizersList'
})

const createCustomizerInstance = apiCall({
  type: CREATE_CUSTOMIZER_INSTANCE,
  method: 'post',
  allowedParamKeys: ['products', 'env'],
  path: ({ payload }) => `/api/v1/customizers/${payload.id}/instance`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const getCustomizerInstance = apiCall({
  type: GET_CUSTOMIZER_INSTANCE,
  method: 'get',
  allowedParamKeys: ['products', 'env'],
  path: ({ payload }) => `/api/v1/instance/${payload.id}`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const updateCustomizerProduct = apiCall({
  type: UPDATE_CUSTOMIZER_PRODUCT,
  method: 'put',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/product/${payload.productId}`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

function* updateCustomizerProductSpecs(action) {
  try {
    yield put(ajaxRequestStart())

    const data = yield call(updateCustomizerProductSpecsRequest, action)
  } catch (error) {
    console.error('Saga error:', error)
  } finally {
    yield put(ajaxRequestEnd())
  }
}

const updateCustomizerProductSpecsRequest = apiCall({
  type: UPDATE_CUSTOMIZER_PRODUCT_SPECS,
  method: 'put',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/spec/${payload.specId}`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const deleteCustomizerProductSpecsGroup = apiCall({
  type: DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP,
  method: 'delete',
  path: ({ payload }) =>
    `/api/v1/instance/${payload.instanceId}/product/${payload.productId}/specsgroup/${payload.group}/index/${payload.groupIndex}`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

function* updateInstanceZoneColor(action) {
  try {
    yield put(ajaxRequestStart())

    const data = yield call(updateInstanceZoneColorRequest, action)
  } catch (error) {
    console.error('Saga error:', error)
  } finally {
    yield put(ajaxRequestEnd())
  }
}

const updateInstanceZoneColorRequest = apiCall({
  type: UPDATE_INSTANCE_ZONE_COLOR,
  method: 'put',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/zone/${payload.zoneId}/color`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const updateInstanceZoneLogo = apiCall({
  type: UPDATE_INSTANCE_ZONE_LOGO,
  method: 'post',
  headers: {
    'Content-Type': '',
    Accept: ''
  },
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/zone/${payload.zoneId}/logo`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const deleteInstanceZoneLogo = apiCall({
  type: DELETE_INSTANCE_ZONE_LOGO,
  method: 'delete',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/zone/${payload.zoneId}/logo`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const updateInstanceProductOption = apiCall({
  type: UPDATE_INSTANCE_PRODUCT_OPTION,
  method: 'put',
  path: ({ payload }) =>
    `/api/v1/instance/${payload.instanceId}/product/${payload.productId}/option`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'customizerInstance'
})

const matchProduct = apiCall({
  type: MATCH_PRODUCT,
  method: 'put',
  path: ({ payload }) =>
    `/api/v1/instance/${payload.instanceId}/zones/${payload.productId}/match/${payload.matchProductId}`,
  payloadOnSuccess: data => data.data,
  requestSelectorKey: 'matchProduct',
  selectorKey: 'customizerInstance'
})

const updateProductImages = apiCall({
  type: UPDATE_PRODUCT_IMAGES,
  method: 'post',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/images`,
  payloadOnSuccess: data => data.data,
  requestSelectorKey: 'productImages',
  selectorKey: 'customizerInstance'
})

const submitOrder = apiCall({
  type: SUBMIT_ORDER,
  method: 'post',
  path: ({ payload }) => `/api/v1/instance/${payload.instanceId}/submit`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'submitOrder'
})

const getUser = apiCall({
  type: GET_USER,
  method: 'get',
  path: ({ payload }) => `/api/v1/users`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'user'
})

export default function* rootSaga() {
  yield takeLatest(SEARCH_CUSTOMIZERS, searchCustomizers)
  yield takeLatest(CREATE_CUSTOMIZER_INSTANCE, createCustomizerInstance)
  yield takeLatest(GET_CUSTOMIZER_INSTANCE, getCustomizerInstance)
  yield takeLatest(UPDATE_CUSTOMIZER_PRODUCT, updateCustomizerProduct)
  yield takeLatest(UPDATE_CUSTOMIZER_PRODUCT_SPECS, updateCustomizerProductSpecs)
  yield takeLatest(DELETE_CUSTOMIZER_PRODUCT_SPECS_GROUP, deleteCustomizerProductSpecsGroup)
  yield takeLatest(UPDATE_INSTANCE_ZONE_COLOR, updateInstanceZoneColor)
  yield takeLatest(UPDATE_INSTANCE_ZONE_LOGO, updateInstanceZoneLogo)
  yield takeLatest(DELETE_INSTANCE_ZONE_LOGO, deleteInstanceZoneLogo)
  yield takeLatest(UPDATE_INSTANCE_PRODUCT_OPTION, updateInstanceProductOption)
  yield takeLatest(UPDATE_PRODUCT_IMAGES, updateProductImages)
  yield takeLatest(SUBMIT_ORDER, submitOrder)
  yield takeLatest(GET_USER, getUser)
  yield takeLatest(MATCH_PRODUCT, matchProduct)
}
