export const fieldIsRequired = fieldName => value =>
  !value ? `${fieldName} is required` : undefined

export const emailIsRequired = fieldIsRequired('Email')

export const nameIsRequired = fieldIsRequired('Name')

export const phoneIsRequired = fieldIsRequired('Phone')

export const storeIsRequired = fieldIsRequired('Store')
