import fp from 'lodash/fp'

export const filterRulesForZone = (specId, rules = []) =>
  fp.filter(item => Number(item.listener.zone) === Number(specId))(rules)

export const validateRules = (zoneId, color, zones, rules, embroiderySpec) => {
  if( process.env.REACT_APP_APP_DEBUG ){
    console.log('Product Validate validateRules zoneId', zoneId)
    console.log('Product Validate validateRules color', color)
    console.log('Product Validate validateRules zones', zones)
    console.log('Product Validate validateRules rules', rules)
    console.log('Product Validate validateRules embroiderySpec', embroiderySpec)
  }

  const zoneRules = filterRulesForZone(zoneId, rules)
  if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules zoneRules', zoneRules)}

  // eslint-disable-next-line
  const zone = zones.reduce((acc, item) => {
    if (zoneId === item.id) {
      acc[item.id] = { ...item, color }
    } else {
      acc[item.id] = item
    }
    return acc
  }, {})

  if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules zone', zone)}

  for (const rule of zoneRules) {
    try {
      // eslint-disable-next-line
      const result = eval(rule.expression)
      if (!result) {
        const keys = Object.keys(rule.listener)
        const delimiter = '!=='
        const start = rule.expression.indexOf(delimiter) + delimiter.length
        const end = rule.expression.indexOf('.', start)
        // eslint-disable-next-line
        const operand1 = eval(rule.expression.substring(0, rule.expression.indexOf('.')))
        if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules operand1', operand1)}
        // eslint-disable-next-line
        const operand2 = eval(rule.expression.substring(start, end))
        if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules operand2', operand2)}
        const target = operand1.id === zoneId ? operand2 : operand1
        if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules target', target)}

        return {
          message: rule.invalid,
          ...(keys.length > 0 ? { type: keys[0], target } : {})
        }
      }
    } catch (ex) {}
  }

  // Manual Embroidery validation
  const embroideryZone = fp.find({ code: 'embroidery', is_active: true })(zones)
  if( process.env.REACT_APP_APP_DEBUG ){console.log('Product Validate validateRules embroideryZone', embroideryZone)}
  if (embroideryZone && typeof embroiderySpec !== 'undefined') {
    if (embroiderySpec && !embroideryZone.color) {
      return 'Please choose a color for your embroidery'
    } else if (!embroiderySpec && embroideryZone.color) {
      return 'Please provide embroidery name'
    }
  }
  return undefined
}
