import { fade } from '@material-ui/core/styles/colorManipulator'

export default theme => ({
  root: {
    overflow: 'hidden',
    height: '100%'
  },
  content: {
    display: 'flex',
    padding: theme.spacing.unit * 4,
    flexDirection: 'column'
  },
  message: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit * 3,
    minHeight: 56
  },
  errorMessage: {
    borderRadius: 5,
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.1)
  },
  zoneArrow: {
    width: 24,
    height: 24
  },
  container: {
    justifyContent: 'space-between'
  },
  bold: {
    fontWeight: 'bolder',
    marginRight: '1em'
  },
  specs: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: `0 ${theme.spacing.unit * 2}px`,
    marginBottom: theme.spacing.unit * 2
  },
  colorSwatch: {
    marginLeft: '10px',
    width: '28px',
    height: '25px',
    boxShadow: '0 0 6px 0 grey',
    borderRadius: '100%',
    border: '2px solid white',
    backgroundColor: '#FFF',
    backgroundSize: '10px 10px',
    backgroundPosition: '0 0, 15px 15px',
    backgroundImage:
      'linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%, lightgrey), linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%, lightgrey)'
  },
  ref: {
    display: 'flex'
  }
})
