import fp from 'lodash/fp'

import { selectors } from '../api'

export const customizersListSelector = selectors.dataSelector('customizersList')

export const isCustomizerListLoadingSelector = selectors.isRequestPending('customizersList', 'get')

export const isInstanceLoadingSelector = state =>
  selectors.isRequestPending('customizerInstance', 'get')(state) ||
  selectors.isRequestPending('customizerInstance', 'post')(state)

export const isInstanceRejectedSelector = selectors.isRequestRejected('customizerInstance', 'get')

export const customizerInstanceSelector = selectors.dataSelector('customizerInstance')

export const productsSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('attributes.products.data'),
  customizerInstanceSelector
)

export const customizerProductsSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('attributes.customizer.data.products.data'),
  customizerInstanceSelector
)

export const customizerZoneOptionsSelector = productId =>
  fp.compose(
    fp.defaultTo([]),
    fp.get('zones.data'),
    fp.find({ id: parseInt(productId, 10) }),
    customizerProductsSelector
  )

export const customizerSpecOptionsSelector = (productId, location = null) =>
  fp.compose(
    fp.filter({ location }),
    fp.defaultTo([]),
    fp.get('specs.data'),
    fp.find({ id: parseInt(productId, 10) }),
    customizerProductsSelector
  )

export const customizerProductOptionsSelector = productId =>
  fp.compose(
    fp.defaultTo([]),
    fp.get('options.data'),
    fp.find({ id: parseInt(productId, 10) }),
    customizerProductsSelector
  )

export const zoneOptionsSelector = productId =>
  fp.compose(
    fp.defaultTo([]),
    fp.get('zones.data'),
    fp.find({ id: parseInt(productId, 10) }),
    productsSelector
  )

export const rulesSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('attributes.customizer.data.rules.data'),
  customizerInstanceSelector
)

export const colorOptionsSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('attributes.colors.data'),
  customizerInstanceSelector
)

export const zoneColorOptionsSelector = (productId, zoneId) =>
  fp.compose(
    fp.defaultTo([]),
    fp.get('colors'),
    fp.find({ id: zoneId }),
    customizerZoneOptionsSelector(productId)
  )

export const colorsApprovedSelector = fp.get('customizer.colorsApproved')

export const isProductImagesSavingSelector = selectors.isRequestPending('productImages', 'post')

export const isMatchingProductSelector = selectors.isRequestPending('matchProduct', 'put')

export const userSelector = selectors.dataSelector('user')

export const isAjaxCallInProgressSelector = state => state.ajaxStatus.ajaxCallsInProgress > 0

export const selectMapOptions = state => state.map.options

export const selectIsMapLoading = state => state.ui.isMapLoading
