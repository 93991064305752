export default theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  mainBody: {
    padding: '2rem'
  },
  mainHeader: {
    padding: '1rem',
    backgroundColor: 'black',
    color: 'white'
  },
  main: {
    overflow: 'hidden',
    textAlign: 'center'
  },
  container: {
    justifyContent: 'space-between'
  },
  heading: {
    marginTop: '2rem'
  },
  customButton: {
    width: '200px'
  }
})
