import { takeLatest } from 'redux-saga/effects'

import { apiCall } from '../api'
import { GET_PRODUCT_LINE } from './types'

const getProductLine = apiCall({
  type: GET_PRODUCT_LINE,
  method: 'get',
  allowedParamKeys: ['env', 'variation', 'customizer'],
  path: () => `/api/v1/lines`,
  payloadOnSuccess: data => data.data,
  selectorKey: 'productLine'
})

export default function* rootSaga() {
  yield takeLatest(GET_PRODUCT_LINE, getProductLine)
}
