export default theme => ({
  bordered: {
    border: '1px solid lightgray',
    '&:before': {
      border: 'none !important'
    },
    '&:after': {
      borderBottom: 'none'
    },
    '&:hover:before': {
      borderBottom: 'none !important'
    }
  },
  padding: {
    paddingLeft: '10px'
  },
  confirmText:{
    border: '1px solid red',
    backgroundColor: 'red'
  }
})
