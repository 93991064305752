import { handleActions } from 'redux-actions'

import { LOCATION_SHOW_LOCATIONS } from './types'

const handleShowLocations = (state, payload) => {
  let ageGroups = []
  let variation = null
  const locations = payload.map(function(current, index, arr) {
    // Construct Age group array
    current.attributes.customizers.data.map(function(current, index, arr) {
      if (ageGroups[current.age_group.id] === undefined)
        ageGroups[current.age_group.id] = current.age_group

      if (current.variation !== undefined) variation = current.variation

      return current
    })

    // Construct location object
    return {
      id: current.id,
      name: current.attributes.name,
      image: current.attributes.image
    }
  })
  return {
    ...state,
    ageGroups,
    locations,
    variation
  }
}

export default handleActions(
  {
    [LOCATION_SHOW_LOCATIONS]: (state, { payload }) => handleShowLocations(state, payload)
  },
  {
    ageGroups: [],
    locations: [],
    variation: null
  }
)
