export default theme => ({
  root: {
    overflow: 'hidden',
    padding: '2rem',
    justifyContent: 'space-between',
    backgroundColor: '#F4F4F4'
  },
  label: {
    marginTop: '0.4rem',
    marginRight: '2rem'
  },
  paddingBottom10: {
    paddingBottom: '10px'
  },
  goButton: {
    fontSize: '0.7rem',
    minHeight: '10px',
    padding: '5px',
    marginTop: '6px'
  },
  logo: {
    width: '230px',
    marginLeft: '70px'
  }
})
