import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const SelectField = ({
  className,
  classes,
  disabled,
  fullWidth,
  helperText,
  input,
  initialValue,
  inputClassName,
  label,
  meta: { touched, error },
  options,
  placeholder
}) => (
  <FormControl className={className} error={touched && !!error} fullWidth={fullWidth}>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      onChange={input.onChange}
      onBlur={input.onBlur}
      value={initialValue || ''}
      displayEmpty={!!placeholder}
      className={classes.bordered}
      classes={{ selectMenu: classes.padding }}
    >
      {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
      {options &&
        options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

export default compose(withStyles(styles))(SelectField)
