import { Workbox } from 'workbox-window'

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
      const wb = new Workbox(swUrl)

      wb.addEventListener('waiting', () => {
        // This event indicates that a new service worker is installed and waiting to activate.
        // You can prompt the user to refresh the page to update immediately.
        console.log('New service worker is waiting.')
        if (confirm('New content is available! Would you like to refresh?')) {
          wb.messageSkipWaiting()
        }
      })

      wb.addEventListener('controlling', () => {
        // This event indicates the new service worker has taken control.
        // At this point, it's safe to reload the page to display the new content.
        if (confirm('New content is available! Would you like to refresh?')) {
          window.location.reload()
        }
      })

      wb.register().catch(error => {
        console.error('Error during service worker registration:', error)
      })

      // Periodically check for an updated service worker.
      setInterval(() => {
        wb.update()
      }, 300000) // 300000 ms = 5 mins
    })
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
