import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class ProductsList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object,
    options: PropTypes.array
  }

  render() {
    const { classes, options, instance } = this.props
    //const spyItems = options.map(item => `product-${item.id}`)

    return (
      <div className={classes.root}>
        <Typography variant="title" color="inherit" gutterBottom>
          <b>Review / Finalize Order</b>
        </Typography>
        <Grid container className={classes.container}>
          <Grid item container className={classes.inner}>
            {options &&
              options.map(item => (
                <Typography
                  gutterBottom
                  key={item.id}
                  href={`#product-${item.id}`}
                  className={classes.reviewOrder}
                >
                  {item.name}
                </Typography>
              ))}
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              <span className={classes.bold}>REF# : </span>
              {instance.id}
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(ProductsList)
