export const buttonStyles = theme => ({
  label: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
})

export default theme => ({
  root: {
    margin: `${theme.spacing.unit * 4}px 0`,
    textAlign: 'left'
  },
  item: {
    display: 'flex',
    alignItems: 'stretch'
  },
  storeItemSelected: {
    backgroundColor: theme.palette.grey[300]
  }
})
