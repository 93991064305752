import React, { Component, Fragment } from 'react'
import fp from 'lodash/fp'
import MenuList from '@material-ui/core/MenuList'
import MuiMenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ArrowUp from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { customizerInstanceSelector, zoneOptionsSelector } from 'redux/modules/customizer/selectors'

import styles, { MenuItemStyle } from './styles'
import SelectColor from '../SelectColor'
import SidebarMenuHeading from 'components/SidebarMenu/SidebarMenuHeading'
import SelectCustomLogo from '../SelectCustomLogo'
import { updateCustomizerProductSpecs } from 'redux/modules/customizer/actions'

const MenuItem = withStyles(MenuItemStyle)(MuiMenuItem)

const getSpecType = (customizerProduct, specId) =>
  fp.compose(
    fp.get('type'),
    fp.find({ id: Number(specId) }),
    fp.defaultTo([]),
    fp.get('specs.data')
  )(customizerProduct)

class SelectZone extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isFormValid: PropTypes.bool,
    product: PropTypes.object.isRequired,
    selectZone: PropTypes.func.isRequired,
    zone: PropTypes.object,
    color: PropTypes.object,
    selectColor: PropTypes.func,
    clearZone: PropTypes.func,
    hoverZone: PropTypes.func,
    unhoverZone: PropTypes.func,
    postHeightMessage: PropTypes.func,
    selectCustomLogo: PropTypes.func,
    submitCustomLogo: PropTypes.func,
    deleteCustomLogo: PropTypes.func,
    customLogos: PropTypes.array,
    customAiFilesLogos: PropTypes.array,
    variation: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selected: props.zone
    }
  }

  componentDidUpdate() {
    const { zone, postHeightMessage } = this.props
    if (this.state.selected !== zone) {
      this.setState({ selected: zone })
    }
    postHeightMessage()
  }

  handleSelectZone = zone => () => {
    this.setState({ selected: zone })
    this.props.selectZone(zone)
  }

  handleSpecChange = (event, newValue, previousValue, name) => {
    const { customizerProduct, instance, isFormValid, updateCustomizerProductSpecs } = this.props
    const specId = name.split('_')[1]
    const type = getSpecType(customizerProduct, name)

    isFormValid &&
      updateCustomizerProductSpecs({
        instanceId: instance.id,
        specId,
        data: type === 'select' ? { option: newValue } : { input: newValue }
      })
  }

  render() {
    const {
      classes,
      zoneOptions,
      color,
      product,
      zone,
      selectColor,
      hoverZone,
      unhoverZone,
      clearZone,
      customLogos,
      customAiFilesLogos,
      variation
    } = this.props
    const { selected } = this.state

    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('SelectZone:: render() zoneOptions', zoneOptions)
      console.log('SelectZone:: render() variation', variation)
      console.log('SelectZone:: render() color', color)
      console.log('SelectZone:: render() product', product)
      console.log('SelectZone:: render() zone', zone)
    }
    
    return (
      <div className={classes.root}>
        <MenuList component="nav" className={classes.sidebar}>
          {zoneOptions &&
            zoneOptions.map((item, key) => (
              item.is_active && item.gui_selectable && (
              <Fragment key={item.id}>
                <MenuItem
                  button
                  onClick={
                    selected && selected.id === item.id ? clearZone : this.handleSelectZone(item)
                  }
                  onMouseEnter={hoverZone(item)}
                  onMouseLeave={unhoverZone}
                  selected={selected ? selected.id === item.id : false}
                >
                  <ListItemText primary={item.name} />
                  <IconButton onClick={clearZone} className={classes.zoneArrow}>
                    {selected && selected.id === item.id ? (
                      <ArrowUp className={classes.dropArrow} />
                    ) : (
                      <ArrowDown className={classes.dropArrow} />
                    )}
                  </IconButton>
                </MenuItem>
                {zone &&
                  selected &&
                  selected.id === item.id &&
                  !item.is_logo  && (
                    <div className={cx(classes.colorPane)}>
                      <SidebarMenuHeading>
                        {this.props.color && this.props.color.name}
                      </SidebarMenuHeading>
                      <SelectColor
                        color={color}
                        product={product}
                        selectColor={selectColor}
                        previewDirection={key < zoneOptions.length - 5}
                        zone={zone}
                        variation={variation}
                      />
                    </div>
                  )}

                  {zone &&
                    selected &&
                    selected.id === item.id &&
                    item.is_logo &&
                    variation === 'team' && (
                      <div className={cx(classes.colorPane)}>
                          <SelectCustomLogo
                            handleCustomLogo={this.props.selectCustomLogo}
                            submitCustomLogo={this.props.submitCustomLogo}
                            deleteCustomLogo={this.props.deleteCustomLogo}
                            customLogos={customLogos}
                            customAiFilesLogos={customAiFilesLogos}
                            zone={item}
                          />
                      </div>
                    )}
              </Fragment>
              )
            ))}
        </MenuList>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  instance: customizerInstanceSelector,
  zoneOptions: (state, props) => zoneOptionsSelector(props.product.id)(state)
})

const actions = {
  updateCustomizerProductSpecs
}

export default compose(
  withRouter,
  connect(
    selector,
    actions
  ),
  withStyles(styles)
)(SelectZone)
