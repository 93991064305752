import React, { Component } from 'react'
//import Button from 'components/GradientButton'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import styles from './styles'
import { customizerInstanceSelector } from 'redux/modules/customizer/selectors'

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
)

class SelectCustomLogo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object,
    handleCustomLogo: PropTypes.func.isRequired,
    submitCustomLogo: PropTypes.func.isRequired,
    deleteCustomLogo: PropTypes.func.isRequired,
    zone: PropTypes.object.isRequired,
    pngFilepondRef: PropTypes.object,
    aiFilepondRef: PropTypes.object,
    customLogos: PropTypes.array.isRequired,
    customAiFilesLogos: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      savedLogo: null,
      currentLogo: null,
      saved: false,
      hasUnsavedLogo: false,
      hasUnsavedAiFile: false,
      savedAiFile: null,
      currentAIFile: null
    }
    this.handleAddPNG = this.handleAddPNG.bind(this)
    this.handleRemovePNG = this.handleRemovePNG.bind(this)
    this.handleSubmitZoneLogo = this.handleSubmitZoneLogo.bind(this)
    this.handleDeleteZoneLogo = this.handleDeleteZoneLogo.bind(this)
    this.handleAddAiFile = this.handleAddAiFile.bind(this)
    this.handleRemoveAiFile = this.handleRemoveAiFile.bind(this)
  }

  componentDidMount() {
    const { customLogos, customAiFilesLogos, zone } = this.props
    let newState = {}
    newState['saved'] = false
    let pngSaved = false
    let aiSaved = false
    if (customLogos[zone.slug] && customLogos[zone.slug].file) {
      if (customLogos[zone.slug].saved) {
        newState['savedLogo'] = customLogos[zone.slug].file
        newState['currentLogo'] = customLogos[zone.slug].file
        pngSaved = true
        newState['hasUnsavedLogo'] = true
      } else {
        newState['savedLogo'] = null
        newState['currentLogo'] = customLogos[zone.slug].file
        pngSaved = false
        newState['hasUnsavedLogo'] = true
      }
      this.props.pngFilepondRef.addFile(customLogos[zone.slug].file.file)
    } else if (this.props.zone.logo && this.props.zone.logo.png_url) {
      newState['savedLogo'] = this.props.zone.logo
      newState['currentLogo'] = this.props.zone.logo
      pngSaved = true
      newState['hasUnsavedLogo'] = false
      this.props.pngFilepondRef.addFile(this.props.zone.logo.png_url)
    }

    if (customAiFilesLogos[zone.slug] && customAiFilesLogos[zone.slug].file) {
      if (customAiFilesLogos[zone.slug].saved) {
        newState['savedAiFile'] = customAiFilesLogos[zone.slug].file
        newState['currentAIFile'] = customAiFilesLogos[zone.slug].file
        aiSaved = true
        newState['hasUnsavedAiFile'] = true
      } else {
        newState['savedAiFile'] = null
        newState['currentAIFile'] = customAiFilesLogos[zone.slug].file
        aiSaved = false
        newState['hasUnsavedAiFile'] = true
      }
      this.props.aiFilepondRef.addFile(customAiFilesLogos[zone.slug].file.file)
    } else if (this.props.zone.logo && this.props.zone.logo.ai_url) {
      newState['savedAiFile'] = this.props.zone.logo
      newState['currentAIFile'] = this.props.zone.logo
      aiSaved = true
      newState['hasUnsavedAiFile'] = false
      this.props.aiFilepondRef.addFile(this.props.zone.logo.ai_url)
    }
    if (pngSaved && aiSaved) {
      newState['saved'] = true
    }
    this.setState(newState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('SelectTeamCustomLogo componentWillReceiveProps nextProps', nextProps)
      console.log('SelectTeamCustomLogo componentWillReceiveProps Props', this.props)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('SelectTeamCustomLogo componentDidUpdate prevProps', prevProps)
      console.log('SelectTeamCustomLogo componentDidUpdate prevState', prevState)
      console.log('SelectTeamCustomLogo componentDidUpdate state', this.state)
    }
  }

  handleAddAiFile(error, file) {
    const { savedAiFile, currentAIFile, saved, hasUnsavedAiFile } = this.state
    if (error) {
      console.log('error AIfile added', error)
    } else {
      if (
        savedAiFile &&
        savedAiFile.filename === file.filename &&
        savedAiFile.fileSize === file.fileSize
      ) {
        this.setState({ currentAIFile: file, hasUnsavedAiFile: false })
        this.props.handleCustomLogo(this.props.zone.slug, 're-add', 'ai', file)
      } else if (
        savedAiFile &&
        currentAIFile &&
        savedAiFile.url !== '' &&
        currentAIFile.url !== '' &&
        savedAiFile.url === currentAIFile.url &&
        saved &&
        !hasUnsavedAiFile
      ) {
        this.setState({ savedAiFile: file, currentAIFile: file, hasUnsavedAiFile: false })
        this.props.handleCustomLogo(this.props.zone.slug, 're-add', 'ai', file)
      } else {
        this.setState({ currentAIFile: file, hasUnsavedAiFile: true })
        this.props.handleCustomLogo(this.props.zone.slug, 'add', 'ai', file)
      }
    }
  }

  handleAddPNG(error, file) {
    const { savedLogo, currentLogo, saved, hasUnsavedLogo } = this.state
    if (error) {
      console.log('error PNGfile added', error)
    } else {
      if (
        savedLogo &&
        savedLogo.filename === file.filename &&
        savedLogo.fileSize === file.fileSize
      ) {
        this.setState({ currentLogo: file, hasUnsavedLogo: false })
        this.props.handleCustomLogo(this.props.zone.slug, 're-add', 'png', file)
      } else if (
        savedLogo &&
        currentLogo &&
        savedLogo.url !== '' &&
        currentLogo.url !== '' &&
        savedLogo.url === currentLogo.url &&
        saved &&
        !hasUnsavedLogo
      ) {
        this.setState({ savedLogo: file, currentLogo: file, hasUnsavedLogo: false })
        this.props.handleCustomLogo(this.props.zone.slug, 're-add', 'png', file)
      } else {
        this.setState({ currentLogo: file, hasUnsavedLogo: true })
        this.props.handleCustomLogo(this.props.zone.slug, 'add', 'png', file)
      }
    }
  }

  handleRemovePNG(file) {
    this.setState({ currentLogo: null, hasUnsavedLogo: false })
    this.props.handleCustomLogo(this.props.zone.slug, 'remove', 'png', file)
  }

  handleRemoveAiFile(file) {
    this.setState({ currentAIFile: null, hasUnsavedAiFile: false })
    this.props.handleCustomLogo(this.props.zone.slug, 'remove', 'ai', file)
  }

  handleSubmitZoneLogo() {
    if (this.state.currentLogo && this.state.currentAIFile) {
      this.props.submitCustomLogo(
        this.props.zone.slug,
        this.state.currentLogo,
        this.state.currentAIFile
      )
      this.setState({
        savedLogo: this.state.currentLogo,
        savedAiFile: this.state.currentAIFile,
        saved: true,
        hasUnsavedLogo: false,
        hasUnsavedAiFile: false
      })
    }
  }

  handleDeleteZoneLogo() {
    const { pngFilepondRef, aiFilepondRef, deleteCustomLogo } = this.props
    const {
      savedLogo,
      currentLogo,
      savedAiFile,
      currentAIFile,
      saved,
      hasUnsavedLogo,
      hasUnsavedAiFile
    } = this.state
    if (savedLogo && savedAiFile && saved) {
      if (currentLogo && !hasUnsavedLogo && currentLogo.filename === savedLogo.filename) {
        pngFilepondRef.removeFile(currentLogo.id)
      }
      if (currentAIFile && !hasUnsavedAiFile && currentAIFile.filename === savedAiFile.filename) {
        aiFilepondRef.removeFile(currentAIFile.id)
      }
      this.setState({
        savedLogo: null,
        currentLogo: null,
        savedAiFile: null,
        currentAIFile: null,
        saved: false,
        hasUnsavedLogo: false,
        hasUnsavedAiFile: false
      })
      deleteCustomLogo(this.props.zone.slug)
    }
  }

  render() {
    const { classes } = this.props
    const {
      savedLogo,
      currentLogo,
      saved,
      savedAiFile,
      currentAIFile,
      hasUnsavedLogo,
      hasUnsavedAiFile
    } = this.state
    const serverConfig = {
      process: null,
      fetch: (url, load, error, progress, abort, headers) => {
        fetch(url)
          .then(res => {
            return res.blob()
          }) // Gets the response and returns it as a blob
          .then(blob => {
            load(blob)
            progress(true, blob.size, blob.size)
          })
          .catch(error => {
            console.log('serverConfig fetch blob', error)
            error(error)
          })
        return {
          abort: () => {
            abort()
          }
        }
      }
    }

    return (
      <div className={classes.root}>
        <FilePond
          ref={ref => (this.props.pngFilepondRef = ref)}
          onaddfile={this.handleAddPNG}
          onremovefile={this.handleRemovePNG}
          allowFileEncode={true}
          allowFileTypeValidation={true}
          acceptedFileTypes={['image/png']}
          allowFileSizeValidation={true}
          maxFileSize={'3MB'}
          labelMaxFileSizeExceeded={'PNG is too large'}
          server={serverConfig}
          labelIdle={
            '<span style="color:#1515a2;font-weight:bold;">PNG::</span> Drag & Drop or <span class="filepond--label-action"> Browse </span>'
          }
        ></FilePond>

        <FilePond
          ref={ref => (this.props.aiFilepondRef = ref)}
          onaddfile={this.handleAddAiFile}
          onremovefile={this.handleRemoveAiFile}
          allowFileEncode={true}
          allowFileTypeValidation={true}
          acceptedFileTypes={['application/postscript']}
          allowFileSizeValidation={true}
          maxFileSize={'3MB'}
          labelMaxFileSizeExceeded={'Ai File is too large'}
          server={serverConfig}
          labelIdle={
            '<span style="color:#1515a2;font-weight:bold;">AI File::</span> Drag & Drop or <span class="filepond--label-action"> Browse </span>'
          }
        ></FilePond>

        <Typography variant="body2" gutterBottom className={classes.logoNotice}>
          PNG file required to preview your logo
        </Typography>

        <Button
          variant="contained"
          color="success"
          onClick={this.handleSubmitZoneLogo}
          disabled={
            (!hasUnsavedLogo && !hasUnsavedAiFile) || currentLogo === null || currentAIFile === null
          }
          className={classes.btnSaveLogo}
        >
          Save
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={this.handleDeleteZoneLogo}
          disabled={!saved && (savedLogo == null || savedAiFile == null)}
          className={classes.btnDeleteLogo}
        >
          Delete upload
        </Button>
      </div>
    )
  }
}

const selector = createStructuredSelector({
  instance: customizerInstanceSelector
})

export default compose(connect(selector), withStyles(styles))(SelectCustomLogo)
