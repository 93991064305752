import React, { Component } from 'react'
import Button from 'components/GradientButton'
import fp from 'lodash/fp'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { touch } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import SpecField from 'components/SpecField'
import styles from './styles'
import { customizerProductsSelector } from 'redux/modules/customizer/selectors'
import {
  validateRequired,
  validateRulesRepeater,
  validateQuantity,
  validateFloat,
  validateLogoDimension
} from '../../validate'
import { isEmpty } from 'utils/helpers'

class ProductSpecsGroup extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    customizerProduct: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    values: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    postHeightMessage: PropTypes.func,
    removeSpecsGroup: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      groupInstance: this.initializeValues()
    }
    this.addRepeater = this.addRepeater.bind(this)
    this.removeRepeater = this.removeRepeater.bind(this)
    this.initializeValues = this.initializeValues.bind(this)
  }

  componentDidMount() {
    const { initialValues, touch } = this.props
    if (initialValues && !isEmpty(initialValues)) {
      let keysInitialValues = Object.keys(initialValues)
      let toTouch = []
      keysInitialValues.forEach(keyInput => {
        toTouch.push(keyInput)
      })
      touch('productSpecsForm', ...toTouch)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductSpecsGroup componentDidUpdate this.state', this.state)
      console.log('ProductSpecsGroup componentDidUpdate this.props', this.props)
    }
  }

  initializeValues() {
    const { initialValues, group } = this.props
    if (initialValues && !isEmpty(initialValues)) {
      let count = 0
      let returnArray = []
      group.items.forEach((spec, idx) => {
        let name = 'spec_' + spec.id
        for (var i = 0; i < 20; i++) {
          if (initialValues[`${name}_r_${i}`]) {
            if (count < i) {
              count = i
            }
          }
        }
      })
      for (var i = 0; i <= count; i++) {
        returnArray.push(group.items)
      }
      return returnArray
    } else {
      return [group.items]
    }
  }

  addRepeater() {
    const { groupInstance } = this.state
    groupInstance.push(this.props.group.items)
    this.setState({ groupInstance: groupInstance })
    this.props.postHeightMessage()
  }

  removeRepeater() {
    const { groupInstance } = this.state
    const { removeSpecsGroup, group } = this.props
    if (groupInstance.length > 1) {
      let groupIndex = groupInstance.length - 1
      groupInstance.pop()
      this.setState({ groupInstance: groupInstance })
      removeSpecsGroup(group.group_slug, groupIndex)
      this.props.postHeightMessage()
    }
  }

  render() {
    const { classes, group, onChange, values, instance, product } = this.props
    const { groupInstance } = this.state

    if (process.env.REACT_APP_APP_DEBUG) {
      console.log('ProductSpecsGroup RENDER product', product)
      console.log('ProductSpecsGroup RENDER instance', instance)
    }

    return (
      <div className={classes.group}>
        <Typography className={classes.groupName}>
          <b>{group.group_name}</b>
        </Typography>
        {groupInstance.map((specs, id_group) => {
          return (
            <div className={classes.groupRepeater}>
              {specs.map((spec, key) => {
                // const validate =
                //   spec.location === null
                //     ? spec.name.toUpperCase().includes('REMARK') // 'PRODUCT REMARKS'
                //       ? [validateRulesRepeater]
                //       : spec.type === 'number'
                //         ? [validateRulesRepeater, validateQuantity]
                //           : spec.type === 'float'
                //             ? [validateRulesRepeater, validateFloat]
                //             : [validateRulesRepeater, validateRequired]
                //     : null
                const validate =
                  spec.location === null
                    ? spec.name.toUpperCase().includes('REMARK') // 'PRODUCT REMARKS'
                      ? [validateRulesRepeater]
                      : spec.type === 'number'
                      ? [validateRulesRepeater, validateQuantity]
                      : spec.type === 'float'
                      ? spec.name.toUpperCase().includes('LOGO DIMENSION')
                        ? [validateRulesRepeater, validateLogoDimension]
                        : [validateRulesRepeater, validateFloat]
                      : [validateRulesRepeater, validateRequired]
                    : null

                return (
                  <Grid container spacing={24} key={spec.id} alignItems="center">
                    <Grid item xs={12} className={classes.noPaddingBottom}>
                      <Typography>
                        <b>{spec.name}</b>
                      </Typography>
                      {spec.description !== null && (
                        <Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: spec.description
                            }}
                          />
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.noPaddingTop}>
                      <SpecField
                        name={'spec_' + spec.id + '_r_' + id_group}
                        spec={spec}
                        value={values ? values['spec_' + spec.id + '_r_' + id_group] : undefined}
                        onChange={onChange}
                        disabled={
                          key > 0
                            ? values
                              ? spec.location !== null ||
                                values['spec_' + specs[0].id + '_r_' + id_group] === undefined
                              : true
                            : spec.location !== null
                        }
                        customize={true}
                        validate={validate}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          )
        })}
        {group.is_repeater && (
          <div className={classes.groupButtons}>
            <Button
              variant="raised"
              color="primary"
              onClick={this.addRepeater}
              type="button"
              className={classes.groupButton}
            >
              Add new {group.group_name}
            </Button>

            <Button
              variant="raised"
              color="primary"
              onClick={this.removeRepeater}
              type="button"
              className={classes.groupButton}
              disabled={groupInstance.length < 2}
            >
              Remove last {group.group_name}
            </Button>
          </div>
        )}
      </div>
    )
  }
}

const actions = {
  touch
}

const selector = createStructuredSelector({
  customizerProduct: (state, props) =>
    compose(fp.find({ id: Number(props.product.id) }), customizerProductsSelector)(state)
})

export default compose(connect(selector, actions), withStyles(styles))(ProductSpecsGroup)
