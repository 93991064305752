import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import LoadingIndicator from 'components/LoadingIndicator'
import ProductSelector from './ProductSelector'
import ReferenceSection from './ReferenceSection'
import styles from './styles'
import Welcome from './Welcome'
import withQueryParamsHandler from 'hocs/withQueryParamsHandler'
import { isInstanceLoadingSelector } from 'redux/modules/customizer/selectors'

class HomePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isInstanceLoading: PropTypes.bool,
    queryParams: PropTypes.object.isRequired
  }

  render() {
    const { classes, isInstanceLoading, queryParams } = this.props

    return (
      <main className={classes.root}>
        <ProductSelector queryParams={queryParams}>
          <Welcome queryParams={queryParams} />
        </ProductSelector>
        <ReferenceSection />
        {isInstanceLoading && <LoadingIndicator />}
      </main>
    )
  }
}

const selector = createStructuredSelector({
  isInstanceLoading: isInstanceLoadingSelector
})

export default compose(
  withQueryParamsHandler,
  connect(selector),
  withStyles(styles)
)(HomePage)
