import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { selectLocation } from 'redux/modules/productLine/actions'
import Typography from '@material-ui/core/Typography'

class SelectLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  handleChange = id => () => {
    const {
      props: { dispatch }
    } = this
    dispatch(selectLocation(id))

    this.setState({
      selected: id
    })
  }

  render() {
    return (
      <ul className="select-options">
        {this.props.locations.map((location, index) => (
          <li
            key={index}
            className={cn('select-options__item', {
              'select-options__item--selected': this.state.selected === location.id
            })}
            onClick={this.handleChange(location.id)}
          >
            <img src={location.image} width="220" alt={location.name} />
            <Typography variant="button" className="select-options__img-label">
              {location.name}
            </Typography>
          </li>
        ))}
      </ul>
    )
  }
}

SelectLocation = connect()(SelectLocation)

export default SelectLocation
