import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import SubmitOrderForm from '../SubmitOrderForm'
import SubmitOrderTitlebar from '../SubmitOrderTitlebar'
import styles from './styles'

class SubmitOrder extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired
  }

  render() {
    const { instance, classes } = this.props
    return (
      <div id="finalize-submit-order" className={classes.root}>
        <SubmitOrderTitlebar instance={instance} />
        <SubmitOrderForm instance={instance} />
      </div>
    )
  }
}

export default compose(withStyles(styles))(SubmitOrder)
