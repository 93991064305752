import React, { Component } from 'react'
import fp from 'lodash/fp'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { touch, change } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { withStyles } from '@material-ui/core/styles'

import SpecField from 'components/SpecField'
import ProductSpecsGroup from '../ProductSpecsGroup'
import styles from './styles'
import { customizerProductsSelector } from 'redux/modules/customizer/selectors'
import { validateRequired, validateRules, validateQuantity } from '../../validate'

const getSpecs = (instance, productId) =>
  fp.compose(
    // fp.filter({ location: null }), // embroidery name field
    fp.defaultTo([]),
    fp.get('specs.data'),
    fp.find({ id: productId }),
    fp.get('attributes.customizer.data.products.data')
  )(instance)

const getSpecType = (customizerProduct, specId) =>
  fp.compose(
    fp.get('type'),
    fp.find({ id: Number(specId) }),
    fp.defaultTo([]),
    fp.get('specs.data')
  )(customizerProduct)

class ProductSpecsTab extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    customizerProduct: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    postHeightMessage: PropTypes.func,
    updateCustomizerProductSpecs: PropTypes.func.isRequired,
    deleteCustomizerProductSpecsGroup: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      values: null,
      groupSpecs: this.groupByGroup(getSpecs(this.props.instance, this.props.product.id)),
    }
  }

  componentDidMount() {
    this.setState({ values: this.props.initialValues })
    // console.log('ProductSpecsTab componentDidMount this.state', this.state)
  }

  componentDidUpdate(prevProps, prevState) {
    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('ProductSpecsTab componentDidUpdate prevProps', prevProps)
      console.log('ProductSpecsTab componentDidUpdate prevState', prevState)
      console.log('ProductSpecsTab componentDidUpdate this.state', this.state)
    }
  }

  handleBlur = (event, newValue, previousValue, name) => {
    const { instance, customizerProduct, updateCustomizerProductSpecs} = this.props
    const { values } = this.state
    const specId = name.split('_')[1]
    const type = getSpecType(customizerProduct, specId)
    let data = {}

    if(type === 'select'){
      data.option = newValue
    }
    else{
      data.input = newValue
    }

    if(name.split('_')[2] && name.split('_')[3] && name.split('_')[2] === 'r'){
      const repeaterIndex = name.split('_')[3]
      data.group_index = repeaterIndex
    }
    else{
      //this.setState({ values: { ...values, [name]: newValue } })
    }

    this.setState({ values: { ...values, [name]: newValue } })

    updateCustomizerProductSpecs({
      instanceId: instance.id,
      specId,
      data: data
    })
  }

  handleRemoveSpecsGroup = (groupSlug, groupIndex) => {
    const { instance, deleteCustomizerProductSpecsGroup, touch, change, product} = this.props
    const { values, groupSpecs } = this.state
    let groupSelected

    let indexNumber = parseInt(groupIndex, 10)

    groupSpecs.forEach( group => {
      if(group.group_slug === groupSlug){
        groupSelected = group
      }
    })

    groupSelected.items.forEach( spec => {
      let indexValue = 'spec_'+spec.id+'_r_'+groupIndex
      if(values[indexValue]){
        delete values[indexValue]
      }
      if(spec.type === 'number'){
        let toTouch = []
        change('productSpecsForm', indexValue, 0)
        for (var i = 0; i < indexNumber; i++) {
          let iValue = 'spec_'+spec.id+'_r_'+i
          toTouch[i]= iValue
        }
        touch('productSpecsForm', ...toTouch)
      }
    })

    deleteCustomizerProductSpecsGroup({
      instanceId: instance.id,
      productId: product.id,
      group: groupSlug,
      groupIndex: groupIndex,
      data: {}
    })
    this.setState({ values: values })
  }


  groupByGroup(collection) {
    var i = 0, val, index, is_repeater,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i]['group'];
        is_repeater = collection[i]['repeater'];
        index = values.indexOf(val);
        let group_name = '';
        let group_slug = '';
        if(val != null){
          group_slug = val;
          group_name = group_slug.replace(/_/g, " ");
        }
        else{
          group_slug = 'none';
          group_name = group_slug;
        }
        if (index > -1)
            result[index].items.push(collection[i]);
        else {
            values.push(val);
            result.push({group_name: group_name, group_slug: group_slug, is_repeater: is_repeater, items: [collection[i]]});
        }
    }
    return result;
  }


  render() {
    const { instance, product, initialValues, classes, postHeightMessage } = this.props
    const { values, groupSpecs } = this.state

    return (
      <div className={classes.root}>
        {groupSpecs.map( group => {
          if(group.group_slug !== 'none'){
            return (
              <ProductSpecsGroup
                group={group}
                classes={classes}
                onChange={this.handleBlur}
                instance={instance}
                product={product}
                initialValues={initialValues}
                values={values}
                removeSpecsGroup={this.handleRemoveSpecsGroup}
                postHeightMessage={postHeightMessage}
              />
            )
          }
          else{
              return (
                group.items.map(spec => {
                    const validate =
                      spec.location === null
                        ? spec.name.toUpperCase().includes('REMARK') // 'PRODUCT REMARKS'
                          ? [validateRules]
                          : spec.type === 'number'
                            ? [validateRules, validateQuantity]
                            : spec.required === true
                              ? [validateRules, validateRequired]
                              : [validateRules]
                        : null

                    return (
                      <Grid container spacing={24} key={spec.id} alignItems="center">
                        <Grid item xs={12} className={classes.noPaddingBottom}>
                          <Typography>
                            <b>{spec.name}</b>
                          </Typography>
                          {(spec.description !== null) && (
                              <Typography>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      spec.description
                                  }}
                                />
                              </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} className={classes.noPaddingTop}>
                          <SpecField
                            name={'spec_'+spec.id}
                            spec={spec}
                            value={values ? values['spec_' + spec.id] : undefined}
                            onChange={this.handleBlur}
                            disabled={spec.location !== null}
                            customize={true}
                            validate={validate}
                          />
                        </Grid>
                      </Grid>
                    )
                })
              )
          }
        })}
      </div>
    )
  }
}

const actions = {
  touch,
  change
}

const selector = createStructuredSelector({
  customizerProduct: (state, props) =>
    compose(
      fp.find({ id: Number(props.product.id) }),
      customizerProductsSelector
    )(state)
})

export default compose(
  connect(selector, actions),
  withStyles(styles)
)(ProductSpecsTab)
