import React from 'react'
import { Field } from 'redux-form'

import InputField from 'components/InputField'
import SelectField from 'components/SelectField'

const getSpecOptions = options => options.map(option => ({ value: option.id, label: option.name }))

const SpecField = ({ label, name, spec, value, onChange, validate, customize, disabled }) =>
  spec.type === 'select' ? (
    <Field
      name={name}
      initialValue={value}
      component={SelectField}
      options={getSpecOptions(spec.options.data)}
      fullWidth
      label={label}
      onChange={onChange}
      validate={validate}
    />
  ) : ['text', 'textarea', 'number', 'float'].includes(spec.type) ? (
    <Field
      name={name}
      component={InputField}
      type={spec.type}
      disabled={disabled}
      fullWidth
      customize={customize}
      label={label}
      onBlur={onChange}
      validate={validate}
      min={0}
    />
  ) : null

export default SpecField
