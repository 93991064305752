import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Checkbox } from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class ConfirmModal extends Component  {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    titleConfirm: PropTypes.string.isRequired,
    textConfirm: PropTypes.string.isRequired,
    textConfirmBtn: PropTypes.string.isRequired,
    checkNeeded: PropTypes.bool.isRequired,
    variation: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      checkedConfirm: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if( process.env.REACT_APP_APP_DEBUG ){
      console.log('ConfirmModal componentDidUpdate prevProps', prevProps)
      console.log('ConfirmModal componentDidUpdate prevState', prevState)
      console.log('ConfirmModal componentDidUpdate state', this.state)
    }
  }

  handleConfirm = () => {
    this.props.handleConfirm()
  };

  handleClose = () => {
    this.props.handleCancel()
  };

  handleChangeCheckbox = (event, checked) => {
    const { checkedConfirm } = this.state
    if(checked){
      this.setState({ checkedConfirm: true });
    }
    else if(!checked && checkedConfirm){
      this.setState({ checkedConfirm: false });
    }
  };

  render() {
    const { classes, titleConfirm, textConfirm, textConfirmBtn, isOpen, checkNeeded} = this.props
    const { checkedConfirm } = this.state

    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="confirm-dialog-title"
          open={isOpen}
          disableBackdropClick="true"
          disableEscapeKeyDown="true"
        >
          <DialogTitle id="confirm-dialog-title" onClose={this.handleClose}>
            {titleConfirm}
          </DialogTitle>
          <DialogContent >
            <div classname={classes.confirmText} >
              { checkNeeded && (
              <Checkbox
                checked={checkedConfirm}
                onChange={this.handleChangeCheckbox}
              />
              )}
              <Typography gutterBottom>
                {textConfirm}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirm} className="green" disabled={!checkedConfirm && checkNeeded}>
              {textConfirmBtn}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}

export default compose(withStyles(styles))(ConfirmModal)
