export default theme => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    width: '30%',
    boxShadow: '-14px -19px 20px 20px rgba(174, 174, 174, 0.1)'
  },
  container: {
    justifyContent: 'space-between',
    padding: '5px'
  },
  titleBox: {
    width: '100%',
    marginBottom: '5px'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    fontSize: '1.1rem',
    textTransform: 'uppercase'
  },
  optionBox: {
    width: '100%',
    marginBottom: '5px'
  },
  option: {
    width: '100%',
    border:'1px solid lightgray',
    textAlign: 'center',
    display: 'inline-block',
    padding: '5px'
  },
  optionSelected: {
    width: '100%',
    border:'1px solid #0eab32',
    textAlign: 'center',
    display: 'inline-block',
    padding: '5px',
    backgroundColor: '#eaebec'
  },
  optionName: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  optionDesc: {
    width: '100%',
    textAlign: 'center'
  },
  focusOption: {},
})
