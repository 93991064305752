import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import { createStructuredSelector } from 'reselect'

import { propsChanged } from 'utils/helpers'
import {
  selectIsMapLoading,
  selectMapOptions
} from '../../../../redux/modules/customizer/selectors'

const centerOfNorthAmerica = { lat: 54.5259614, lng: -105.2551187 }

class StoresMap extends PureComponent {
  componentDidUpdate(prevProps) {
    if (propsChanged(['options'], this.props, prevProps)) {
      this.fitBounds()
    }
  }

  getBounds(options) {
    const bounds = new window.google.maps.LatLngBounds()
    options.forEach(item => {
      bounds.extend(
        new window.google.maps.LatLng(item.attributes.latitude, item.attributes.longitude)
      )
    })
    return bounds
  }

  fitBounds() {
    const { options } = this.props
    const bounds = this.getBounds(options)
    if (this.map && bounds && options.length > 0) {
      this.map.fitBounds(bounds)
    }
  }

  handleMapMounted = ref => {
    this.map = ref
    this.fitBounds()
  }

  render() {
    const { options } = this.props

    return (
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
        <GoogleMap
          mapContainerStyle={{ width: '400px', height: '400px' }}
          defaultZoom={4}
          maxZoom={9}
          center={centerOfNorthAmerica}
          onLoad={this.handleMapMounted}
        >
          {options.map(item => (
            <Marker
              key={item.id}
              position={{
                lat: parseFloat(item.attributes.latitude),
                lng: parseFloat(item.attributes.longitude)
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  options: selectMapOptions,
  isLoading: selectIsMapLoading
})

export default connect(mapStateToProps)(StoresMap)
