export default theme => ({
  root: {
    padding: '3rem',
    border: '1px solid lightgray'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  },
  noPaddingBottom: {
    paddingBottom: '0 !important'
  },
  noPaddingTop: {
    paddingTop: '5px !important'
  },
  group: {
    paddingBottom: '20px !important'
  },
  groupRepeater: {
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
    borderBottom: '1px solid #6c757d26'
  },
  groupButtons: {
    paddingTop: '5px !important',
    paddingBottom: '5px !important'
  },
  groupButton: {
    marginRight: '5px !important'
  },
  groupName: {
    textTransform: 'capitalize',
    fontSize: '1.1rem',
    textDecoration: 'underline'
  }
})
