import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { selectAgeGroup } from 'redux/modules/productLine/actions'
import Typography from '@material-ui/core/Typography'

class SelectAgeGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  handleChange = id => () => {
    const {
      props: { dispatch }
    } = this
    dispatch(selectAgeGroup(id))

    this.setState({
      selected: id
    })
  }

  render() {
    return (
      <ul className="select-agegroup">
        {this.props.ageGroups.map((ageGroup, index) => (
          <li
            key={index}
            className={cn('select-agegroup__item', {
              'select-agegroup__item--selected': this.state.selected === ageGroup.id
            })}
            onClick={this.handleChange(ageGroup.id)}
          >
            <Typography variant="button">{ageGroup.name}</Typography>
          </li>
        ))}
      </ul>
    )
  }
}

SelectAgeGroup = connect()(SelectAgeGroup)

export default SelectAgeGroup
